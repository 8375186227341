(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("linkFileController", linkFileController);

	function linkFileController($scope, $mdDialog, $http, $timeout, $filter, $translate, coreData, services, fileStorageList, errorHandling,
		fileInfoProvider) {
		var vm = this;

		vm.folderName = undefined;
		vm.getIconClass = getIconClass;

		$scope.connectedServices = services;
		$scope.selectedService = undefined;
		$scope.selectedServiceType = '';
		$scope.selectedFile = undefined;
		$scope.currentFolder = '';
		$scope.previousFolderPath = '';
		$scope.folders = [];
		$scope.fileStorageData = $.extend(true, [], fileStorageList);
		$scope.fileStorageEnabled = fileStorageList.length > 0;
		$scope.isLoading = false;

		var fileStorageRoot = undefined;

		var pickerLoaded = false;

		$scope.processFileStorage = function (folder) {
			folder.isFolder = true;
			//recursively check through subfolders.
			for (var i = 0; i < folder.subFolders.length; ++i) {
				$scope.processFileStorage(folder.subFolders[i]);
			}
			if (folder.files) {
				/*
				folder.files = $.grep(folder.files, function (file) {
					return file.publicDownloadLink != null;
				});
				*/
				for (var j = 0; j < folder.files.length; ++j) {
					folder.files[j].name = folder.files[j].fileName;
				}
			}
			//remove subfolders that don't have any public files or subfolders.
			/*
			folder.subFolders = $.grep(folder.subFolders, function (subFolder) {
				var hasPublicFiles = false;
				if (subFolder.files) {
					for (var i = 0; i < subFolder.files.length; ++i) {
						if (subFolder.files[i].publicDownloadLink) {
							hasPublicFiles = true;
							break;
						}
					}
				}
				return hasPublicFiles || subFolder.subFolders.length > 0;
			});
			*/
		};

		if ($scope.fileStorageEnabled) {
			fileStorageRoot = $scope.fileStorageData[0].data.subFolders[1].data;
			$scope.processFileStorage(fileStorageRoot);
		}

		$scope.selectService = function (service) {
			$scope.selectedService = service;
			$scope.previousFolderPath = '-1|';
			switch (service.type) {
				case 2: $scope.selectedServiceType = 'dropbox'; break;
				case 4: $scope.selectedServiceType = 'onedrive'; break;
				case 7: $scope.selectedServiceType = 'leitzcloud'; break;
				case 8: $scope.selectedServiceType = 'vboxx'; break;
				case 9:
					$scope.selectedServiceType = 'googlepicker';
					openPicker();
					return;
			}
			$scope.openFolder(undefined);
		};

		function openPicker() {
			if (!pickerLoaded)
				gapi.load("client:picker", onPickerInitialized);
			else
				onPickerInitialized();

			function onPickerInitialized() {
				pickerLoaded = true;
				$http.get("~/api/v1/settings/google-picker-args")
					.then(showPicker, errorHandling.report);

				function showPicker(data) {
					if (!data.data.success) {
						errorHandling.Report(data);
					}

					var view = new google.picker.DocsView(google.picker.ViewId.DOCS)
						.setIncludeFolders(true)
						.setEnableDrives(false)
						.setMode(google.picker.DocsViewMode.LIST);

					const picker = new google.picker.PickerBuilder()
						.enableFeature(google.picker.Feature.MINE_ONLY)
						.addView(view)
						.setOAuthToken(data.data.token)
						.setDeveloperKey(data.data.key)
						.setAppId(data.data.id)
						.setCallback(pickerCallback)
						.build();
					picker.setVisible(true)
				}
			}

			function pickerCallback(result) {
				if (result.action == 'cancel' || (result.action == "picked" && result.docs.length == 0)) {
					$mdDialog.cancel();
					return;
				}

				if (result.action != "picked")
					return;
				$mdDialog.hide({
					type: $scope.selectedService.type,
					typeString: $scope.selectedServiceType,
					id: result.docs[0].id,
					fileName: result.docs[0].name,
					link: result.docs[0].link,
				});
			}
		}

		function getIconClass(item) {
			if (item.isFolder)
				return "toolsicon-folder_fill";

			var icon = fileInfoProvider.iconFromExtension(item.type);
			return `toolsicon-${icon}`;
		}

		$scope.openFolder = function (folder, previous) {
			if (folder && folder.id === '-1|') {
				$scope.selectedService = undefined;
				$scope.previousFolderPath = '-1|';
				$scope.folders = [];
				$scope.currentFolder = '';
				vm.folderName = "";
				$scope.selectedFile = undefined;
				return;
			}
			//Local file storage
			if ($scope.selectedService.type == -1 && $scope.fileStorageEnabled) {
				$scope.isLoading = false;
				if (!folder) {
					$scope.currentFolder = fileStorageRoot.path;
					vm.folderName = $translate.instant(fileStorageRoot.name) || $scope.selectedService.friendlyName;

					fileStorageRoot.subFolders = $.grep(fileStorageRoot.subFolders, function (f) { return f.name !== "ONLINE_MEETING_FILES"; });
					$scope.folders = fileStorageRoot.files ? fileStorageRoot.subFolders.concat(fileStorageRoot.files) : fileStorageRoot.subFolders;
				} else {
					if (folder.id) { folder.path = folder.id; }
					if (previous) {
						//remove the folder we are now in from the previous path.
						$scope.previousFolderPath = $scope.previousFolderPath.split('|');
						$scope.previousFolderPath = $.grep($scope.previousFolderPath, function (fPath) { return fPath.trim() != ""; });
						$scope.previousFolderPath.length = $scope.previousFolderPath.length - 1;
						$scope.previousFolderPath = $scope.previousFolderPath.join('|');
						if ($scope.previousFolderPath[$scope.previousFolderPath.length] != "|") { $scope.previousFolderPath = $scope.previousFolderPath + "|"; }
						let newFolder = $scope.findFileStorageFolder(folder.path.replace('-1|', ''));
						if (newFolder) {
							$scope.folders = [];
							$scope.folders = newFolder.files ? newFolder.subFolders.concat(newFolder.files) : newFolder.subFolders;
						}
						$scope.currentFolder = folder.path;
						vm.folderName = $translate.instant(newFolder.name);
						$scope.selectedFile = undefined;
					} else {
						$scope.previousFolderPath = '-1|' + $scope.currentFolder.replace('-1|', '');
						let newFolder = $scope.findFileStorageFolder(folder.path.replace('-1|', ''), folder);
						if (newFolder) {
							$scope.folders = [];
							$scope.folders = newFolder.files ? newFolder.subFolders.concat(newFolder.files) : newFolder.subFolders;
						}
						$scope.currentFolder = folder.path;
						vm.folderName = $translate.instant(newFolder.name);
						$scope.selectedFile = undefined;
					}
				}

				for (var i = 0; i < $scope.folders.length; i++) {
					if ($scope.folders[i].name == 'PUBLIC_CHAT_FILES') {
						$scope.folders[i].name = $translate.instant($scope.folders[i].name);
						break;
					}
				}
			} else {
				// Cloud storage integration (OneDrive, Dropbox, etc.)
				$scope.isLoading = true;
				if (!folder) {
					$scope.folders.length = 0;
					vm.folderName = $scope.selectedService.friendlyName;
					$http.get('~/api/v1/mail/filestorage/' + $scope.selectedServiceType)
						.then(function (success) {
							$scope.isLoading = false;
							$scope.currentFolder = success.data.currentFolderID ? success.data.currentFolderID : 'root';
							$scope.folders = success.data.items;
						}, errorHandling.report);
				} else {
					vm.folderName = folder.name;
					if (previous) {
						$scope.isLoading = true;
						$scope.folders.length = 0;
						$scope.previousFolderPath = $scope.previousFolderPath.split('|');
						$scope.previousFolderPath = $.grep($scope.previousFolderPath, function (fPath) { return fPath.trim() != ""; });
						var newFolder = $scope.previousFolderPath[$scope.previousFolderPath.length - 1];
						if (newFolder == 'root')
							newFolder = undefined;
						$scope.selectedFile = undefined;
						$http.get('~/api/v1/mail/filestorage/' + $scope.selectedServiceType + (newFolder ? ("/" + (newFolder.replace("id:", ""))) : ('')))
							.then(function (success) {
								$scope.isLoading = false;
								$scope.currentFolder = success.data.currentFolderID ? success.data.currentFolderID : 'root';
								$scope.previousFolderPath.length = $scope.previousFolderPath.length - 1;
								$scope.previousFolderPath = $scope.previousFolderPath.join('|') + '|';
								$scope.folders = success.data.items;
								vm.folderName = success.data.currentFolderName || $scope.selectedService.friendlyName;
							}, errorHandling.report);
					} else {
						$scope.isLoading = true;
						$scope.folders.length = 0;
						$scope.previousFolderPath += $scope.currentFolder + '|';
						$scope.selectedFile = undefined;
						$http.get('~/api/v1/mail/filestorage/' + $scope.selectedServiceType + "/" + folder.id.replace("id:", ""))
							.then(function (success) {
								$scope.isLoading = false;
								$scope.currentFolder = success.data.currentFolderID ? success.data.currentFolderID : 'root';
								$scope.folders = success.data.items;
								vm.folderName = success.data.currentFolderName || $scope.selectedService.friendlyName;
							}, errorHandling.report);
					}
				}
			}
		};

		$scope.findFileStorageFolder = function (path, folder) {
			if (!$scope.fileStorageEnabled)
				return;
			if (!folder) {
				folder = fileStorageRoot;
			}
			if (folder.path === path) {
				return folder;
			}
			for (var i = 0; i < folder.subFolders.length; ++i) {
				if (folder.subFolders.path === path) {
					return folder.subFolders[i];
				}
				return $scope.findFileStorageFolder(path, folder.subFolders[i]);
			}
			return undefined;
		};

		$scope.done = function () {
			if ($scope.selectedServiceType === "" && (!$scope.selectedFile.published || $scope.selectedFile.publicDownloadLink == null)) {
				$http.get('~/api/v1/filestorage/' + $scope.selectedFile.id +'/getlink/public')
					.then(function (success) {
						$scope.selectedFile.published = true;
						$scope.selectedFile.publicDownloadLink = success.data.publicLink;
						finishModal();
					}, errorHandling.report);
			}
			else
				finishModal();
			

			function finishModal()
			{
				var baseUrl = coreData.baseUrl.split('/');
				baseUrl.length = 3;
				baseUrl = baseUrl.join('/');
				$mdDialog.hide({
					'type': $scope.selectedService.type,
					'typeString': $scope.selectedServiceType,
					'id': $scope.selectedFile.id,
					'link': !$scope.selectedServiceType ? (baseUrl + '/' + $scope.selectedFile.publicDownloadLink) : $scope.selectedFile.publicDownloadLink,
					'folder': $scope.currentFolder == 'root' ? undefined : $scope.currentFolder,
					'fileName': $scope.selectedFile.name
				});
			}

		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.onKeyUpFile = function (file, ev) {
			if (ev.which === 13) {
				$scope.selectedFile = file;
			}
		};

		$scope.onKeyUpFolder = function (folder, previous, ev) {
			if (ev.which === 13) {
				$scope.openFolder(folder, previous);
			}
		};
		
		$scope.selectFile = function (file) {
			$scope.selectedFile = file;
		};
	}
})();