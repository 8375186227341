(function () {

    var chartIdGenerator = new Date().getTime();

    angular
        .module('smartermail')
        .directive('reportCardContent', reportCardContentDirective);

    /* @ngInject */
    function reportCardContentDirective($compile, $timeout, $window, $filter, $http, $stateParams, $state, $log) {
        return {
            restrict: 'E',
            transclude: true,
            link: function (scope, element, attrs) {
                var cardMarkup = {};
                var cardMarkupSet = false;

                var showLegend = true;
                if (scope.content.hide && scope.content.hide == "1")
                    return;
                var labelNoName = false;
                switch (scope.content.dataType) {
                    case "label_noName": initLabel(false); break;
                    case "label": initLabel(true); break;
                    case "colored": initColored(); break;
                    case "pie_no-legend": initPie(false); break;
                    case "pie": initPie(true); break;
                    case "barGroup": initBarGroup(); break;
                    case "percentBar": initPercentBar(); break;
                    case "stackedBar": initStackedBar(); break;
                    case "percentCircle": initPercentCircle(); break;
                    case "lineChart": initLineChart(); break;
                    case "disk-usage-legend": initDiskUsageLegend(); break;
                }

                if (scope.$parent.$parent.charts)
                    scope.$parent.$parent.charts[scope.content.cardTitle || scope.$parent.card.cardTitle] = scope.theChart;
                if (scope.content.dataType !== 'disk-usage-legend')
                    element.append($compile(cardMarkup)(scope));

                ////////////////////////////

                function parseSVG(s) {
                    var div = document.createElementNS('http://www.w3.org/1999/xhtml', 'div');
                    div.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg">' + s + '</svg>';
                    var frag = document.createDocumentFragment();
                    while (div.firstChild.firstChild)
                        frag.appendChild(div.firstChild.firstChild);
                    return frag;
                }

                function StringToNumberData(str) {
                    var mult = 1;
                    if (str.indexOf("KB") != -1)
                        mult = 1024;
                    else if (str.indexOf("MB") != -1)
                        mult = 1024 * 1024;
                    else if (str.indexOf("GB") != -1)
                        mult = 1024 * 1024 * 1024;
                    else if (str.indexOf("TB") != -1)
                        mult = 1024 * 1024 * 1024 * 1024;
                    else if (str.indexOf("PB") != -1)
                        mult = 1024 * 1024 * 1024 * 1024 * 1024;

                    return Number(str.replace(/[^\d.-]/g, '')) * mult;
                }

                function navigate(type, report) {
                    window.location.href = "root#/reports/" + type + '/' + report;
                }

                function navigateTo(target) {
                    $state.go('index.reports.reports-details', { type: $state.params.type, report: target.toLowerCase() });
                    //alert('navigating to ' + target);
                }

                function Round(number, precision) {
                    var factor = Math.pow(10, precision);
                    var tempNumber = number * factor;
                    var roundedTempNumber = Math.round(tempNumber);
                    return roundedTempNumber / factor;
                }

                function initLabel(name) {
                    labelNoName = !name;
                    scope.theChart = {};
                    scope.theChart.updateValue = function (value) {
                        scope.content.data.value = $filter('safeNumber')(value, 0);
                    };

                    scope.theChart.updateTotal = function (value) {
                        if (scope.$parent.card.isPairedCard) {
                            for (var i = 0; i < 2; i++) {
                                for (var j = 0; j < scope.$parent.card.cardContents[i].length; j++) {
                                    if (scope.$parent.card.cardContents[i][j].cardTitle === scope.content.cardTitle) {
                                        scope.$parent.card.cardData.values[i] = $filter('safeNumber')(value, 1);
                                        break;
                                    }
                                }
                            }
                        } else {
                            scope.$parent.card.cardData.value = $filter('safeNumber')(value, 1);
                        }

                    };

                    if (!labelNoName) {
                        cardMarkup = angular.element(
                            "<div class='report-card-content-label'>" +
                            "<div class='rccl-name'>{{content.data.name | translate}}</div>" +
                            (scope.content.data.value != undefined ? ("<div class='rccl-value' " +
                                (scope.content.data.color != undefined ? "style='color:" + "{{content.data.color}}" : '') + "'>" +
                                (scope.content.data.format && scope.content.data.format == "bytes" ? "{{content.data.value | bytes}}" : "{{content.data.value | safeNumber}}") +
                                (scope.content.data.unit ? " {{content.data.unit | translate}}" : "") + "</div>") : "") +
                            "</div>");
                    }
                    else {
                        cardMarkup = angular.element(
                            "<div class='report-card-content-label'>" +
                            "<div class='rccl-name' style='display:none;'>{{content.data.name | translate}}</div>" +
                            (scope.content.data.value != undefined ? ("<div class='rccl-value' " +
                                (scope.content.data.color != undefined ? "style='color:" + "{{content.data.color}}" : '') + "'>" +
                                (scope.content.data.format && scope.content.data.format == "bytes" ? "{{content.data.value | bytes}}" : "{{content.data.value | safeNumber}}") +
                                (scope.content.data.unit ? " {{content.data.unit | translate}}" : "") + "</div>") : "") +
                            "</div>");
                    }
                }

                function initColored() {
                    scope.theChart = {};

                    scope.theChart.updateValue = function (value) {
                        scope.content.data.value = $filter('safeNumber')(value, 0);
                    };

                    element.parent().parent().css("background-color", scope.content.data.color);

                    cardMarkup = angular.element(
                        '<div class="report-card-colored">' +
                        '<div class="rcc-title">{{content.data.name | translate}}</div>' +
                        '<div class="rcc-data">{{content.data.value | safeNumber }}</div>' +
                        '</div>'
                    );
                }

                function initPie(legend) {
                    var chartId = chartIdGenerator++;
                    var legendValuesBlock = "";
                    var centerValue = "";
                    var defaultColors = ['#5497a7', '#8263a9', '#de8b6b', '#b1d480', '#56cbc3'];
                    var colorMap = {};
                    var pieChartData = [];
                    var colors = [];
                    scope.theChart = {}
                    scope.theChart.updateValue = function (values) { }

                    showLegend = !!legend;

                    $.each(scope.content.data, function (i, v) {
                        switch (v.type) {
                            case 'center':
                                centerValue = $filter('safeNumber')(v.value, 0);
                                if (v.suffix) centerValue += v.suffix;
                                break;
                            case 'data':
                                var translatedName = $filter("translate")(v.name);
                                var thisColor = v.color || defaultColors.pop();
                                colorMap[v.name] = thisColor;
                                colors.push(thisColor);
                                pieChartData.push([translatedName, v.value]);
                                legendValuesBlock += "<div class='legend-row'><div class='circle' style='background-color:" + thisColor + ";'>" + "</div><div>" + translatedName + "</div><div>" + $filter("safeNumber")(v.value) + "</div></div>";
                                break;
                        }
                    });

                    // Define markup for loading the piechart
                    var legendText = showLegend ? "<div class='legend-block'>" + legendValuesBlock + "</div>" : "";
                    cardMarkup = angular.element(
                        "<div class='report-card-content-pie' style='width:100%;position:relative;'>\
						    <div id='theChart"+ chartId + "' style='height: 200px;'></div> \
							"+ legendText + "\
						</div>");
                    cardMarkupSet = true;

                    $timeout(function () {
                        if (pieChartData.length > 0 && _.some(pieChartData, function (v) { return v && v.length > 1 && v[1] != 0 })) {
                            c3.generate({
                                bindto: '#theChart' + chartId,
                                color: { pattern: colors },
                                data: { columns: pieChartData, type: "donut" },
                                donut: { title: centerValue },
                                legend: { show: false }
                            });
                            $('#theChart' + chartId).removeClass('hidden-chart');
                        } else {
                            $('#theChart' + chartId).addClass('hidden-chart');
                        }
                    }, 100);

                }

                function initBarGroup() {
                    scope.theChart = {}
                    scope.theChart.throttledLoad = _.throttle(refreshBarGroup, 900, true);
                    scope.theChart.updateValue = function (name, value) {
                        // Update scope data
                        $.each(scope.content.data, function (i, v) { if (v.name === name) v.value = +value; });

                        // Recalculate card label
                        var total = _.reduce(scope.content.data, function (memo, item) { return memo + item.value; }, 0)
                        scope.$parent.card.cardData.value = total;

                        // Redraw the chart
                        scope.theChart.throttledLoad();
                    };

                    scope.chartReadyHandler = function (chartWrapper) { };
                    scope.theChart.throttledLoad();

                    function refreshBarGroup() {

                        // Determine max value
                        var maxValue = 1;

                        $.each(scope.content.data, function (i, v) {
                            if (v.type == 'bar' && +v.value > maxValue) 
                                maxValue = +v.value;
                        });

                        var markup = "<div class='report-card-content-bar-group'>";
                        $.each(scope.content.data, function (i, v) {
                            switch (v.type) {
                                case 'bar':
                                    var label = $filter("translate")(v.name);
                                    var valueAsText = ((v.format && v.format == "bytes") ? $filter("bytes")(v.value) : $filter("number")(v.value));
                                    var widthPercent = v.value * 100 / maxValue;
                                    if (widthPercent < 0.1) widthPercent = 0.1; else if (widthPercent > 100) widthPercent = 100;

                                    markup += "\
                                        <div class='label'>" + label +"</div>\
                                        <div class='bar-container'> \
                                            <div class='bar-box'><div class='bar-box-item' style='width: "+ widthPercent+ "%; background-color: "+v.color+";'></div></div> \
                                            <div class='bar-text'>"+ valueAsText +"</div> \
                                        </div>";
                                    break;
                            }
                        });
                        markup += "</div>";

                        if (!cardMarkupSet) {
                            cardMarkup = angular.element(markup);
                            cardMarkupSet = true;
                        }
                        else
                            element.empty().append(markup);
  }
                }

                function initPercentBar() {
                    if (scope.content.data.total == 0)
                        return;

                    scope.theChart = {}
                    scope.theChart.updateValue = function (name, value) {
                        $.each(scope.content.data, function (i, v) {
                            if (v.label == name)
                                v.value = +value;
                        });
                        refreshBarGroup();
                    };
                    scope.theChart.updateTotal = function (name, total) {
                        $.each(scope.content.data, function (i, t) {
                            if (t.label == name)
                                t.total = +total;
                        });
                        refreshBarGroup();
                    }
                    scope.theChart.updateValues = function (value, total) {
                        scope.content.data.value = value;
                        scope.content.data.total = total;
                        refreshBarGroup();
                    }
                    scope.content.data.value = StringToNumberData(scope.content.data.value);
                    scope.content.data.total = StringToNumberData(scope.content.data.total);
                    scope.chartReadyHandler = function (chartWrapper) { };
                    refreshBarGroup();

                    function refreshBarGroup() {
                        var percent = Math.round(100 * 100 * (scope.content.data.value / scope.content.data.total)) / 100;
                        var colors = [scope.content.data.color != undefined ? scope.content.data.color : '#b1d480', '#cccccc'];
                        var label = $filter('number')(percent, 2) + "%";
                         
                        var markup = "<div class='report-card-content-precentbar' style='background-color: " + colors[1] + "'>";
                        markup += "<div class='bar-box-item' style='background-color: "+colors[0]+"; width: "+ percent + "%'></div>";
                        markup += "<div class='bar-box-text'>" + label+"</div>";
                        markup += "</div>";

                        if (!cardMarkupSet) {
                            cardMarkup = angular.element(markup);
                            cardMarkupSet = true;
                        }
                        else
                            element.empty().append(markup);
     }
                }

                function initStackedBar() {
                    if (scope.content.data.total == 0)
                        return;

                    scope.theChart = {}
                    scope.theChart.throttledLoad = _.throttle(refreshBarGroup, 900, true);
                    scope.theChart.updateValue = function (name, value) {
                        $.each(scope.content.data, function (i, v) {
                            if (v.label == name)
                                v.value = +value;
                        });
                        scope.theChart.throttledLoad();
                    };
                    scope.chartReadyHandler = function (chartWrapper) { };
                    scope.theChart.throttledLoad();

                    function refreshBarGroup() {
                        var total = _.reduce(scope.content.data, function (memo, item) { return memo + item.value; }, 0)

                        if (scope.newChart) {
                            $.each(scope.content.data, function (i, v) {
                                if (!scope.newChart)
                                    return;
                                var barItem = element.find(".bar-box-item[x-label=" + v.label + "]");
                                var legendItem = element.find(".report-card-content-stackedbar-legend-item[x-label=" + v.label + "] .value");
                                var percent = total == 0 ? 0 : (v.value * 100 / total);
                                if (barItem && legendItem) {
                                    barItem.width(percent + "%");
                                    legendItem.text($filter("number")(v.value) + " " + $filter("translate")(scope.content.unit));
                                }
                                else {
                                    scope.newChart = null;
                                }
                            });
                        }

                        // It is possible for newChart to be cleared out during this function, so we cannot use an else
                        if (!scope.newChart)
                        {
                            var markup = "<div class='report-card-content-stackedbar' style='background-color: #cccccc44;'>";
                            $.each(scope.content.data, function (i, v) {
                                var percent = total == 0 ? 0 : (v.value * 100 / total);
                                markup += "<div class='bar-box-item' x-label='" + v.label + "' style='background-color: " + v.color + "; width: " + percent + "%'></div>";
                            });
                            markup += "</div>";
                            if (scope.content.legend) {
                                $.each(scope.content.data, function (i, v) {
                                    markup += "<div class='report-card-content-stackedbar-legend-item' x-label='" + v.label + "'>";
                                    markup += "  <div class='color-dot' style='background-color: " + v.color + ";'></div>";
                                    markup += "  <div class='label'>" + $filter("translate")(v.label) + "</div>";
                                    markup += "  <div class='value'>" + $filter("number")(v.value) + " " + $filter("translate")(scope.content.unit) + "</div>";
                                    markup += "</div>";
                                });
                            }

                            if (!cardMarkupSet) {
                                cardMarkup = angular.element(markup);
                                cardMarkupSet = true;
                            }
                            else
                                element.empty().append(markup);
                            scope.newChart = element;
                        }
                    }
                }

                function initPercentCircle() {
                    scope.chartReadyHandler = function (chartWrapper) { };

                    //Set options and load data
                    scope.theChart = {};
                    scope.theChart.throttledLoad = _.throttle(refreshBarGroup, 900, true);
                    scope.theChart.throttledLoad();

                    function refreshBarGroup() {
                        var strokeColor = scope.content.data.color != undefined ? scope.content.data.color : "#ff3333";
                        var fillColor = $.shadeColor(strokeColor, -0.2);

                        scope.content.data.value = StringToNumberData(scope.content.data.value);
                        scope.content.data.total = StringToNumberData(scope.content.data.total);
                        if (scope.content.data.total < scope.content.data.value)
                            scope.content.data.total = scope.content.data.value;
                        var percent = Math.round(100 * 100 * (scope.content.data.value / scope.content.data.total)) / 100;

                        var strokeWidth = 20;
                        var dimension = 180;
                        var padding = 5;
                        var radius = dimension / 2 - padding - (strokeWidth / 2);
                        var circumference = Math.PI * (radius * 2);
                        var percentage = percent;
                        if (percentage < 0) { percentage = 0; }
                        if (percentage > 100) { percentage = 100; }
                        var circumferencePercentage = ((100 - percentage) / 100) * circumference;

                        cardMarkup = angular.element("\
                        <div class='report-card-content-percent-circle' data-pct='55'> \
                            <svg width='"+ dimension + "' height='" + dimension + "' viewPort='0 0 100 100' version='1.1' xmlns='http://www.w3.org/2000/svg'> \
                                <circle class='back' r='"+ radius + "' cx='" + (dimension / 2) + "' cy='" + (dimension / 2) + "' fill='" + fillColor + "' stroke-width='" + strokeWidth + "' stroke-dasharray='" + circumference + "'></circle> \
                                <circle class='bar' r='"+ radius + "' cx='" + (dimension / 2) + "' cy='" + (dimension / 2) + "' fill='transparent' stroke-width='" + strokeWidth + "' stroke-dasharray='" + circumference + "' stroke='#ccc' ></circle> \
                                <circle class='bar' r='"+ radius + "' cx='" + (dimension / 2) + "' cy='" + (dimension / 2) + "' fill='transparent' stroke-width='" + strokeWidth + "' stroke-dasharray='" + circumference + "' stroke='" + strokeColor + "' stroke-dashoffset='" + circumferencePercentage + "'></circle> \
                                <text text-anchor='middle' x='" + (dimension / 2) + "' y='" + ((dimension / 2) + 5) + "' font-family='Arial' font-size='32' stroke='none' stroke-width='0' fill='#fff'>" + percent + "%</text> \
                                <text text-anchor='middle' x='" + (dimension / 2) + "' y='" + ((dimension / 2) + 25) + "' font-family='Arial' font-size='14' stroke='none' stroke-width='0' fill='#fff'>" + $filter('translate')(scope.content.data.label) + "</text> \
                            </svg> \
                        </div>");
                    }
                }

                function initLineChart() {
                    var chartId = chartIdGenerator++;
                    scope.c3Columns = [];
                    scope.theChart = {
                        data: {},
                        throttledLoad: _.throttle(function () {
                            scope.theChart.c3Chart.load({ columns: scope.c3Columns });
                        }, 900, true)
                    };

                    try {

                        $.each(scope.content.data.items, function (i, v) { scope.c3Columns.push([$filter("translate")(v)]); });
                        $.each(scope.content.data.values, function (i, v) {
                            $.each(v, function (j, w) { scope.c3Columns[j].push(w); });
                        });

                        cardMarkup = angular.element(
                            "<div class='report-card-content-lineChart' style='width:100%;position:relative;'>\
	    					    <div id='theChart"+ chartId + "' style='height: 200px;'></div> \
    						</div>");

                        $timeout(function () {
                            scope.theChart.c3Chart = c3.generate({
                                bindto: '#theChart' + chartId,
                                color: { pattern: scope.content.colors || ['#5497a7', '#8263a9', '#de8b6b', '#b1d480', '#56cbc3'] },
                                data: { columns: scope.c3Columns, type: "line" },
                                legend: { show: false },
                                axis: { x: { show: false }, y: {
                                    min: 0,
                                    padding: { bottom: 0 },
                                    tick: { format: function (d) { return scope.content.suffix == " %" ? d+" %" : d; }}
                                } },
                                spline: { interpolation: { type: 'monotone' } },
                                point: { show: false },
                                transition: { duration: 0 }
                            });
                        }, 100);
                    } catch (e) { console.trace(e) }

                    scope.theChart.updateValue = function(values) {
                        if (!scope.theChart.c3Chart)
                            return;

                        $.each(values, function (i, v) {
                            $.each(v, function (j, w) {
                                scope.c3Columns[j].push(w);
                            });
                        });

                        $.each(scope.c3Columns, function(j, w) {
                            var c = scope.c3Columns[j];
                            if (c.length > 301) { c.splice(1, c.length - 301); }
                        });

                        scope.theChart.throttledLoad();
                    }

                    scope.theChart.updateValues = function (values) {
                        scope.c3Columns = [];
                        $.each(scope.content.data.items, function (i, v) { scope.c3Columns.push([$filter("translate")(v)]); });
                        scope.theChart.updateValue(values);
                    };
                }

                function initDiskUsageLegend() {
                    var bodyDirection = document.getElementsByTagName("body")[0].getAttribute("dir");
                    var unlimited = false;
                    if (scope.content.data.unlimited && scope.content.data.unlimited == "1")
                        unlimited = true;

                    $http.get("~/api/v1/report/disk-usage/" + $stateParams.type)
                        .then(function (response) {
                            try {
                                scope.content = response.data;
                                var legendBlock = "";
                                var usageLine = "";

                                /******************
                                 * Mailbox
                                 ******************/
                                //Header
                                usageLine = $filter('bytes')(scope.content.mailboxUsed);
                                if (!unlimited)
                                    usageLine += ' (' + Round((scope.content.mailboxUsed / scope.content.allowed) * 100, 1) + '%)';

                                legendBlock += '<div class="legend-row"><div class="circle" style="background-color:#5396A6;"></div>'
                                    + '<div>' + $filter('translate')('MAILBOX_USAGE') + ' - ' + usageLine + '</div>'
                                    + '<div></div></div>';

                                //Body
                                scope.content.sources.forEach(function (source) {
                                    legendBlock += '<div class="legend-row folder">'
                                        + '<div>' + $filter('translate')(source.name)  + '</div>'
                                        + '<div>' + $filter('bytes')(source.value);
                                    legendBlock += '</div></div>';

                                });

                                /******************
                                 * File Storage
                                 ******************/
                                usageLine = $filter('bytes')(scope.content.fileStorageUsed);
                                if (!unlimited)
                                    usageLine += ' (' + Round((scope.content.fileStorageUsed / scope.content.allowed) * 100, 1) + '%)';
                                legendBlock += '<div class="legend-row"><div class="circle" style="background-color:#B2C991;"></div>'
                                    + '<div>' + $filter('translate')('FILE_STORAGE_USAGE') + ' - ' + usageLine + '</div>'
                                    + '<div></div></div>';

                                /******************
                                * Team Workspace
                                ******************/
                                usageLine = $filter('bytes')(scope.content.meetingWorkspaceUsed);
                                if (!unlimited)
                                    usageLine += ' (' + Round((scope.content.meetingWorkspaceUsed / scope.content.allowed) * 100, 1) + '%)';
                                legendBlock += '<div class="legend-row"><div class="circle" style="background-color:#8768AE;"></div>'
                                    + '<div>' + $filter('translate')('ONLINE_MEETING_USAGE') + ' - ' + usageLine + '</div>'
                                    + '<div></div></div>';

                                /******************
                                * Public Chat Files
                                ******************/
                                usageLine = $filter('bytes')(scope.content.chatFilesUsed);
                                if (!unlimited)
                                    usageLine += ' (' + Round((scope.content.chatFilesUsed / scope.content.allowed) * 100, 1) + '%)';
                                legendBlock += '<div class="legend-row"><div class="circle" style="background-color:#e6800f;"></div>'
                                    + '<div>' + $filter('translate')('CHAT_FILES') + ' - ' + usageLine + '</div>'
                                    + '<div></div></div>';

                                /******************
                                 * X of Max used
                                 ******************/
                                var usageHeader = "";
                                if (!unlimited)
                                    usageHeader = "<div class='reports-usage-header'>"
                                        + $filter('translate')('REPORTS_DISK_USED_STRING', { used: scope.content.fileStorageUsed + scope.content.mailboxUsed + scope.content.meetingWorkspaceUsed + scope.content.chatFilesUsed, allowed: scope.content.allowed })
                                        + "</div>";

                                cardMarkup = angular.element(usageHeader + "<div class='legend-block'>" + legendBlock + "</div>");
                                element.append($compile(cardMarkup)(scope));
                            }
                            catch (e) { }
                        }, function (r) {
                        });
                }
            }

        };
    }
})();