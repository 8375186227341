(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("notificationsController", notificationsController);

    function notificationsController($scope, $mdDialog, $filter, $translate, $log, $compile, notifications, NgTableParams,
        gridCheckboxes, browserNotifications, userDataService, preferencesStorage, ngTableEventsChannel, themesService) {

        var vm = this;
        vm.tableParams = new NgTableParams(
            {
                sorting: { dt: 'desc' },
                count: 10
            },
            {
                dataset: [],
                counts: [10, 25, 50, 100, 200]
            }
        );
        vm.checkboxes = gridCheckboxes.init("id");
        vm.checkboxes.table = vm.tableParams;
        vm.initialized = false;

        // Browser Notifications
        vm.browserNotificationPermission = browserNotifications.permission;
        vm.openHelpLinkForBrowserNotifications = openHelpLinkForBrowserNotifications;

        // Functions
        vm.dismissSelected = dismissSelected;
        vm.dismissAll = dismissAll;
        vm.snoozeSelected = snoozeSelected;
        vm.searchItems = searchItems;
        vm.editItem = editItem;
        vm.contextMenuGridItem = contextMenuGridItem;

        activate();

        //////////////////////////

        function activate() {
            themesService.ensureActivated();

            loadNotifications();
            userDataService.init();

            ngTableEventsChannel.onPagesChanged(vm.checkboxes.checkAllChecked);
        }

        async function loadNotifications() {
            try {
                var result = await notifications.getNotifications() || [];

                result = result.filter(x => !x.snoozedUntil || new Date(x.snoozedUntil) < new Date());
                result.forEach(function (notif) {
                    if (notif.textData) {
                        Object.keys(notif.textData).forEach(function (key) {
                            notif.textData[key] = $filter("translate")(notif.textData[key]);
                        });
                        notif.title = $filter("translate")(notif.title);

                        var translated = "";
                        notif.body.split("<br>")
                            .forEach(function (msgPart) {
                                translated += $filter("translate")(msgPart, notif.textData) + "<br>";
                            })
                        notif.body = translated.trim();

                        translated = "";
                        notif.bodySafe.split("<br>")
                            .forEach(function (msgPart) {
                                translated += $filter("translate")(msgPart, notif.textData) + "<br>";
                            })
                        notif.bodySafe = translated.trim();
                    }
                    if (notif.body)
                        notif.body = notif.body.replace(/<br\s?\/?>/g, "\r\n");
                    if (notif.bodySafe)
                        notif.bodySafe = notif.bodySafe.replace(/<br\s?\/?>/g, "\r\n");
                });

                vm.checkboxes.reset();
                vm.tableParams.settings({ dataset: result });
                vm.tableParams.reload();
                vm.initialized = true;

            } catch (e) {

            }
        }

        async function editItem(card, ev) {
            try {
                await notifications.openNotificationDialog(card, ev);
                loadNotifications();
            } catch (e) { }
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            var items = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((items.length > 1 && !_.some(items, function (val) { return val === item.id })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.id];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: 'dismissSelected', click: dismissSelected, translateKey: 'DISMISS' },
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $('#context-menu-area');
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        async function dismissSelected(event) {
            try {
                var cards = vm.checkboxes.getCheckedItems();
                if (!cards || !cards.length)
                    return;
                await notifications.dismiss(cards);
                loadNotifications();
            } catch (e) { }
        }

        async function dismissAll(ev) {
            try {
                var result = await notifications.getNotifications() || [];
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter('translate')('CONFIRMATIONS_DISMISS_ALL_ITEMS', { items: result && result.length }))
                    .ok($filter('translate')("DISMISS"))
                    .targetEvent(ev);
                await $mdDialog.show(confirm);
                await notifications.dismissAll(ev);
                window.close();
            } catch (e) { }
        }

        async function snoozeSelected(minutes, event) {
            try {
                var cards = vm.checkboxes.getCheckedItems();
                if (!cards || !cards.length)
                    return;
                await notifications.snooze(cards, minutes);
                vm.checkboxes.reset();
                loadNotifications();
            } catch (e) { }
        }

        function searchItems() {
            vm.checkboxes.reset();
            vm.tableParams.filter({ $: vm.searchText });
        }

        function openHelpLinkForBrowserNotifications() {
            const url = "https://help.smartertools.com/smartermail/current/?page=" + encodeURIComponent('browser-notifications-disabled') + "&v=" + stProductVersion;
            console.log('NAVIGATING TO HELP PAGE: ' + url);
            window.open(url, "help");
        }
    }

})();