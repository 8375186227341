(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("simpleXmppService", simpleXmppService);

    function simpleXmppService($rootScope, $filter, $timeout, $log, $sanitize, $localStorage, $translate,
        coreData, coreDataContacts, coreDataSettings, browserNotifications, preferencesStorage, tokenRefreshService,
        userDataService, claimsService, authStorage, AuthenticationService, popupService, chatVideoService, signalrHubManager) {

        var idCounter = 1;
        var uid = moment().valueOf();
        var connectionAttempts = 5;
        var unavailableTimeouts = {};

        // TODO: Get pics from api/v1/contacts/domain

        // Data
        var vm = this;
        vm.connection = null;
        vm.boshUrl = null;
        vm.host = null;
        vm.username = null;
        vm.email = null;
        //vm.status = "connecting";
        vm.connected = false;
        vm.contactCategories = [];
        vm.logTraffic = false;
        vm.parameters = {
            get status() {
                var value = preferencesStorage.getSortingFilteringParam("chat", "status");
                if (value === undefined) {
                    value = "available"; preferencesStorage.setSortingFilteringParam("chat", "status", value);
                }
                return value;
            },
            set status(value) {
                var prev = preferencesStorage.getSortingFilteringParam("chat", "status");
                if (value != 'failed' && prev != 'failed')
                    preferencesStorage.setSortingFilteringParam("chat", "prevStatus", prev);

                vm.currentStatus = value;
                preferencesStorage.setSortingFilteringParam("chat", "status", value);
            },

            get prevStatus() {
                var value = preferencesStorage.getSortingFilteringParam("chat", "prevStatus");
                if (value === undefined) {
                    value = "available"; preferencesStorage.setSortingFilteringParam("chat", "prevStatus", value);
                }
                return value;
            },
            set prevStatus(value) { preferencesStorage.setSortingFilteringParam("chat", "prevStatus", value); },

            get unreadCounts() {
                var value = preferencesStorage.getSortingFilteringParam("chat", "unreadCounts");
                if (value === undefined) {
                    value = {}; preferencesStorage.setSortingFilteringParam("chat", "unreadCounts", value);
                }
                return value;
            },
            set unreadCounts(value) { preferencesStorage.setSortingFilteringParam("chat", "unreadCounts", value); },

            get popupOpen() {
                return preferencesStorage.getLocalParam("popupOpen");
            },

            get registeredContacts() {
                var value = preferencesStorage.getContactStatusesParam("registered");
                if (value === undefined) {
                    value = {}; preferencesStorage.setContactStatusesParam("registered", value);
                }
                return value;
            },
            set registeredContacts(value) { preferencesStorage.setContactStatusesParam("registered", value); },

            //get resourceID() {
            //	var value = preferencesStorage.getSortingFilteringParam("chat", "resourceID");
            //	if (value === undefined) {
            //		value = ""; preferencesStorage.setSortingFilteringParam("chat", "resourceID", value);
            //	}
            //	return value;
            //},
            //set resourceID(value) { preferencesStorage.setSortingFilteringParam("chat", "resourceID", value); },
        };

        // Functions
        vm.authenticateOAuth2 = authenticateOAuth2;
        vm.markRead = markRead;
        vm.setStatus = setStatus;
        vm.findContact = findContact;
        vm.registerContact = registerContact;
        vm.deleteRegistration = deleteRegistration;
        vm.popout = popout;
        vm.init = init;

        // Startup
        if (window.location.href.indexOf('popout') > 0 && window.location.href.indexOf("popout/email") === -1)
            return;

        if (!window.Strophe)
            throw new Error('Please make sure to include Strophe library. http://strophe.im/strophejs/');

        function init() {
            if (claimsService.isSysAdmin())
                return;

            $(window).off('storage', onStorageEvent);
            window.addEventListener('storage', onStorageEvent);
            window.addEventListener("beforeunload", function () {
                if (vm.connection)
                    vm.connection.disconnect();
            });
            $rootScope.$on('signalR.chatVideo.receivingCall', onReceivingCall);

            userDataService
                .init()
                .then(function () {
                    vm.isVisible = userDataService.user.settings.features && userDataService.user.settings.features.enableChat && !claimsService.impersonating();
                    if (vm.isVisible) {
                        // Check if chat popup is open
                        vm.querying = true;
                        preferencesStorage.setSortingFilteringParam("chat", "queryPopup", true);

                        // If popup not open, connect to xmpp like normal
                        vm.timeout = $timeout(function () {
                            //$log.log("No popup, connecting to XMPP");
                            vm.querying = false;
                            vm.parameters.unreadCounts = {};
                            connectXmpp();
                        }, 5000, false);
                    }
                }, function () { });

            cleanUnreadCounts();
            loadUnreadCounts();
            recalculateUnread();
        }

        function deleteRegistration() {
            var registeredContacts = vm.parameters.registeredContacts;
            delete registeredContacts[uid];
            vm.parameters.registeredContacts = registeredContacts;
        }

        function onStorageEvent(event) {
            //$log.log("Storage popupOpen: " + vm.parameters.popupOpen);

            if (preferencesStorage.getSortingFilteringParam("chat", "doLogout")) {
                preferencesStorage.setSortingFilteringParam("chat", "doLogout", undefined);
                AuthenticationService.Logout("xmpp service (activate)");
            }

            if (vm.parameters.popupOpen) {
                vm.connected = true;
                //$log.log("Popup open, loading unread counts");
                if (vm.timeout) { $timeout.cancel(vm.timeout); vm.timeout = undefined; }
                if (vm.connection) { vm.connection.disconnect(); vm.connection = undefined; }
                vm.querying = false;

                var status = vm.parameters.status
                if (status != vm.currentStatus) {
                    vm.currentStatus = status;
                    $rootScope.$broadcast('xmpp.property-changed', { status: status });
                }

                loadUnreadCounts();
                recalculateUnread();
            } else if (vm.isVisible && !vm.querying) {
                if (!vm.connection) {
                    //$log.log("Popup closed, connecting to XMPP");
                    connectXmpp();
                }
            }

            if (preferencesStorage.getContactStatusesParam("query") && vm.connection) {
                //$log.log("Queried for contact statuses");
                checkRegisteredStatuses();
                preferencesStorage.setContactStatusesParam("query", false);
            }

            if (event.key === "contactStatuses" && !vm.connection) {
                //$log.log("Contact statuses updated");
                var registeredContacts = vm.parameters.registeredContacts;

                for (var email in registeredContacts[uid]) {
                    var contact = findContact(email);
                    if (!contact) {
                        vm.contactCategories[0].contacts.push({ jid: email, status: registeredContacts[uid][email] });
                    } else
                        contact.status = registeredContacts[uid][email];
                }

                $rootScope.$broadcast("xmpp.contacts-changed");
            }
        }

        function cleanUnreadCounts() {
            var counts = vm.parameters.unreadCounts;
            var toRemove = [];
            for (var key in counts) {
                if (counts[key].unread <= 0) {
                    toRemove.push(key);
                }
            }

            for (var i = 0; i < toRemove.length; i++) {
                delete counts[toRemove[i]]
            }
        }

        function connectXmpp() {
            if (vm.parameters.status == 'offline') {
                $rootScope.$broadcast('xmpp.property-changed', { status: vm.parameters.status });
                return;
            }
            vm.boshUrl = stSiteRoot + "httpbind";
            vm.authenticateOAuth2(claimsService.getEmailAddress(), authStorage.getToken());
        }

        function authenticateOAuth2(username, token) {
            if (vm.parameters.status == 'failed')
                vm.parameters.status = vm.parameters.prevStatus;
            vm.connected = false;
            vm.username = username;
            vm.originalUsername = claimsService.getRootEmail();
            vm.displayName = coreData.user.displayName ? coreData.user.displayName : vm.username;

            // TODO: End existing connection
            if (vm.connection) { vm.connection.disconnect(); }
            vm.connection = new Strophe.Connection(vm.boshUrl, undefined, signalrHubManager.connection);

            vm.connection.rawInput = function (elem) {
                if (vm.logTraffic) $log.log("RECV:\r\n" + elem);
            }

            vm.connection.rawOutput = function (elem) {
                if (vm.logTraffic) $log.log("SENT:\r\n" + elem);
            }


            //// If multiple resource IDs being online when they shouldn't be becomes a problem this can be implemented
            //// But there's a problem. After disconnecting, the next connection attempt will fail. The httpbind will timeout.
            //// However, no error occurs within the connect callback, making it difficult to deal with.
            //// This is why it isn't currently implemented, because if it isn't a problem I do not want to waste time on it
            //var resourceID;
            //if (vm.parameters.resourceID)
            //	resourceID = vm.parameters.resourceID;
            //else {
            //	resourceID = "/Webmail_" + vm.connection.getUniqueId().split("-")[0];
            //	vm.parameters.resourceID = resourceID;
            //}
            vm.connection.connect(vm.originalUsername, token, function (status) {
                if (status === Strophe.Status.CONNECTED) {
                    onConnected();
                } else if (status === Strophe.Status.AUTHFAIL) {
                    if (connectionAttempts-- <= 0) { onFailed(); return; }
                    tokenRefreshService.refreshToken()
                        .then(function (response) {
                            authenticateOAuth2(username, response.data.accessToken)
                        });
                } else if (status === Strophe.Status.CONNFAIL) { //|| status === Strophe.Status.DISCONNECTED
                    onFailed();
                }
            });
        }

        function markRead(contact) {
            contact.unreadCount = 0;
            recalculateUnread();
        }

        function setStatus(newStatus) {
            if (vm.parameters.popupOpen) {
                vm.parameters.status = newStatus;
                $rootScope.$broadcast('xmpp.property-changed', { status: vm.parameters.status });
                return;
            }

            if (!vm.connection && newStatus !== 'offline') {
                vm.parameters.status = newStatus;
                connectXmpp();
                return;
            } else if (!vm.connection) return;

            switch (newStatus) {
                case "available":
                    vm.connection.send(window.$pres());
                    break;
                case "away":
                    vm.connection.send(window.$pres().c('show').t('away'));
                    break;
                case "dnd":
                    vm.connection.send(window.$pres().c('show').t('dnd'));
                    break;
                case "offline":
                    if (vm.connection)
                        vm.connection.disconnect();
                    vm.connection = undefined;
                    vm.contactCategories = [];
                    break;
                default:
                    return;
            }
            vm.parameters.status = newStatus;
            $rootScope.$broadcast('xmpp.property-changed', { status: vm.parameters.status });
        }

        function timeoutStatus() {
            $timeout(function () {
                if (!vm.connection || !vm.connection.connected || !vm.connection.authenticated) { return; }
                setStatus(vm.parameters.status);
                timeoutStatus();
            }, 120000);
        }

        function popout(selectContact) {
            if (selectContact === "recent")
                preferencesStorage.setSortingFilteringParam("chat", "selectRecentUnread", true);
            else
                preferencesStorage.setSortingFilteringParam("chat", "selectContact", selectContact.jid);

            vm.querying = true;
            $timeout(function () { vm.querying = false; }, 5000);
            $rootScope.$applyAsync();
            window.open((stSiteRoot || '/') + 'interface/root#/popout/chat', "chat", "resizable=1, " + popupService.dimensions.chat).focus();
        }

        // Private Events
        function onConnected() {
            connectionAttempts = 5;
            var xmppRequest = $iq({ type: "get", id: "_roster_" + (idCounter++) }).c("query", { xmlns: Strophe.NS.ROSTER });
            timeoutStatus();
            vm.connected = true;
            vm.connection.sendIQ(xmppRequest, onRoster);
            vm.connection.messageCarbons.enable(onMessageCarbon);
        }

        function onFailed() {
            connectionAttempts = 5;
            vm.parameters.status = "failed";
            $rootScope.$broadcast('xmpp.property-changed', { status: vm.parameters.status });
        }

        function onMessageCarbon(carbon) {
            var body = $(carbon.innerMessage).children("body").text() || "";
            if (!body)
                return;

            onMessage(carbon.innerMessage);
        }

        function onMessage(msg) {
            try {
                var from = $(msg).attr("from");
                var sender = from.split("/")[0];
                var body = $(msg).children("body").text() || "";
                var state = $(msg).find("active,composing,paused,gone")[0];
                var bareJid = Strophe.getBareJidFromJid(from);
                var msgType = $(msg).attr('type');
                var foundItem = findContact(bareJid);
                if (foundItem) {
                    var name = foundItem.name || bareJid;
                    if (bareJid === coreData.user.emailAddress) {
                        name = coreData.user.displayName ? coreData.user.displayName : name;
                    }

                    if (msgType == 'chat' && body) {
                        var html_body = $('html > body', msg);
                        if (html_body.length > 0) {
                            html_body = $('<div>').append(html_body.contents()).html();
                        } else {
                            html_body = null;
                        }

                        var isplain = !html_body;
                        if (!html_body)
                            html_body = body;

                        foundItem.unreadCount++;

                        storeUnreadCount(foundItem);
                        recalculateUnread();

                        // TODO: Check if this is already a visible page or not
                        if (coreDataSettings.userSettings.notifyOnChatMessages) {
                            var convertedHtml = $("<div>" + html_body + "</div>").text();
                            if (convertedHtml.toLowerCase().indexOf('http://') == 0 ||
                                convertedHtml.toLowerCase().indexOf('https://') == 0)
                                convertedHtml = $filter('translate')('LINK_RECEIVED');

                            browserNotifications.show(name,
                                {
                                    body: convertedHtml,
                                    tag: bareJid,
                                    icon: (stSiteRoot || '/') + 'interface/img/notifications/chat.png',
                                    notifyClick: function (e) {
                                        popout(foundItem);
                                    }
                                });
                        }

                    }
                }
            } catch (ex) { $log.error(ex); }
            return true;
        }

        function onGroupMessage(iq) {
            return true;
        }

        function onRoster(roster) {
            //I'm using a try catch here because i've seen errors happen here and chrome doesn't catch and throw them itself.
            try {
                vm.contactCategories = [];
                $(roster).find("item").each(function () {
                    addRosterContact(contactFromIqItem(this));
                });
                loadUnreadCounts();
                recalculateUnread();
                checkRegisteredStatuses();

                vm.connection.addHandler(onRosterChanged, Strophe.NS.ROSTER, 'iq', 'set');
                vm.connection.addHandler(onPresence, null, 'presence');
                vm.connection.addHandler(onIQ, null, 'iq');
                vm.connection.addHandler(onMessage, null, 'message', "chat");
                vm.connection.addHandler(onGroupMessage, null, 'message', "groupchat");
                vm.setStatus(vm.parameters.status);
            } catch (exception) {
                $log.error(exception); //Chrome doesn't seem to like the error I get here so the throw statement doesn't work on its on.
                throw new Error(exception);
            }
        }

        function onPresence(presence) {
            var online = [];
            var offline = [];
            while (presence != null) {
                if ($(presence).attr('type') === 'unavailable')
                    offline.push(presence);
                else
                    online.push(presence);
                presence = presence.nextSibling;
            }

            for (var i in offline) {
                setPresence(offline[i]);
            }

            for (var i in online) {
                setPresence(online[i]);
            }

            return true;
        }

        function setPresence(presence) {
            var ptype = $(presence).attr('type');
            var from = $(presence).attr('from');
            var bareJid = Strophe.getBareJidFromJid(from);
            var resourceID = Strophe.getResourceFromJid(from);

            if (bareJid.toLowerCase() == userDataService.user.emailAddress.toLowerCase() && ptype === 'unavailable') {
                setStatus(vm.parameters.status);
            }

            if (vm.roomJoining == from && vm.onRoomJoined) {
                if (ptype === 'error')
                    vm.onRoomJoined(false, from);
                var isRoomJoinRequestResponse = $(presence).find("status[code='110']").length > 0;
                if (isRoomJoinRequestResponse)
                    vm.onRoomJoined(true, from);
            }

            if (ptype === 'error')
                return;

            var foundItem = findContact(bareJid);
            if (foundItem) {
                if (unavailableTimeouts[bareJid]) {
                    $timeout.cancel(unavailableTimeouts[bareJid]);
                    delete unavailableTimeouts[bareJid];
                }

                if (!foundItem.statuses) foundItem.statuses = {};

                if (foundItem.status === "room")
                    foundItem.status = "room";
                else if (ptype === 'unavailable') {
                    if (resourceID)
                        foundItem.statuses[resourceID] = 'offline';

                    var offline = true;
                    for (var key in foundItem.statuses) {
                        if (foundItem.statuses[key] != 'offline') {
                            offline = false; break;
                        }
                    }

                    if (offline) {
                        unavailableTimeouts[bareJid] = $timeout(function () {
                            foundItem.status = 'offline';
                            checkRegisteredStatus(bareJid, foundItem.status);
                        }, 5000);
                    } else {
                        var available = false;
                        var dnd = false;
                        for (var key in foundItem.statuses) {
                            if (foundItem.statuses[key] === 'offline')
                                continue;
                            else if (foundItem.statuses[key] === 'available') {
                                available = true; break;
                            }
                            else if (foundItem.statuses[key] === 'dnd')
                                dnd = true;
                        }

                        if (!available) {
                            if (dnd)
                                foundItem.status = "dnd";
                            else
                                foundItem.status = "away";
                        }
                    }
                }
                else {
                    var show = $(presence).find("show").text();

                    if (resourceID)
                        foundItem.statuses[resourceID] = show === "" || show === "chat" ? "available" : (show === "dnd" ? "dnd" : "away");

                    if (show === "" || show === "chat")
                        foundItem.status = "available";
                    else if (show === "dnd")
                        foundItem.status = "dnd";
                    else {
                        // foundItem.status = "away";
                        var away = true;
                        for (var key in foundItem.statuses) {
                            if (foundItem.statuses[key] === 'offline')
                                continue;
                            if (foundItem.statuses[key] != 'away') {
                                away = false; break;
                            }
                        }

                        if (away) {
                            foundItem.status = 'away';
                            checkRegisteredStatus(bareJid, foundItem.status);
                        }
                    }
                }
                checkRegisteredStatus(bareJid, foundItem.status);
            }

            return;
        }

        function onRosterChanged(iq) {
            $(iq).find('item').each(function () {
                var contact = contactFromIqItem(this);
                if (contact.subscription == "remove")
                    removeRosterContact(contact);
                else
                    addRosterContact(contact);
            });
            recalculateUnread();
            checkRegisteredStatuses();
            return true;
        }

        function onIQ(iq) {
            return true;
        }

        function onReceivingCall(ev, data) {
            if (vm.parameters.popupOpen) {
                return;
            }

            var contact = findContact(data.sourceEmail);

            var name = contact.name || data.sourceEmail;
            browserNotifications.show(name,
                {
                    body: $translate.instant("CHAT_CALL_FROM", { name: name }),
                    tag: data.sourceEmail,
                    icon: (stSiteRoot || '/') + 'interface/img/notifications/chat.png',
                    notifyClick: function (e) {
                        preferencesStorage.setSortingFilteringParam("chat", "receivingCall", data);
                        popout(contact);
                    }
                });
        }

        // Private Functions
        function recalculateUnread() {
            var temp = 0;
            for (var cat in vm.contactCategories) {
                for (var contactIndex in vm.contactCategories[cat].contacts) {
                    var contact = vm.contactCategories[cat].contacts[contactIndex];
                    if (contact && contact.unreadCount) {
                        temp += contact.unreadCount;
                    }
                }
            }
            vm.unread = temp;
            $rootScope.$broadcast('xmpp.property-changed', { unreadCount: temp });
        }

        function storeUnreadCount(contact) {
            var counts = vm.parameters.unreadCounts;

            if (!counts[contact.jid]) counts[contact.jid] = {};
            counts[contact.jid].unread = contact.unreadCount;
            counts[contact.jid].time = moment();

            vm.parameters.unreadCounts = counts;
        }

        function loadUnreadCounts() {
            var counts = vm.parameters.unreadCounts;

            if (!vm.contactCategories || !vm.contactCategories[0]) {
                vm.contactCategories = [{ contacts: [] }];
            }

            for (var key in counts) {
                var foundItem = findContact(key);
                if (foundItem) foundItem.unreadCount = counts[key].unread ? counts[key].unread : 0;
                else vm.contactCategories[0].contacts.push({ jid: key, unreadCount: counts[key].unread });
            }
        }

        function contactFromIqItem(iqItem) {
            var jid = $(iqItem).attr("jid");
            var bareJid = Strophe.getBareJidFromJid(jid);
            var displayJid = jid.split("@")[0] + "@" + coreData.user.domain;
            var name = $(iqItem).attr("name") || bareJid;
            var subscription = $(iqItem).attr("subscription");
            var group = $(iqItem).find("group").text() || "";
            var contact = coreDataContacts.getContactByEmail(jid);
            var pic = null;
            if (contact && contact.image && !contact.image.indexOf('data=default') > -1) { pic = contact.image }
            return {
                jid: jid,
                bareJid: bareJid,
                displayJid: displayJid,
                name: name,
                subscription: subscription,
                group: group,
                pic: pic
            };
        }

        function findContact(jid) {
            if (!jid) return null;
            jid = jid.toLowerCase();
            for (var i = 0; i < vm.contactCategories.length; i++) {
                var theList = vm.contactCategories[i];
                if (!theList.contacts) continue;
                for (var j = 0; j < theList.contacts.length; j++) {
                    var foundItem = theList.contacts[j];
                    if (foundItem && foundItem.jid == jid)
                        return foundItem;
                }
            }
            return null;
        }

        function registerContact(email) {
            if (!email) return;
            email = email.toLowerCase();
            var registeredContacts = vm.parameters.registeredContacts;
            if (!registeredContacts[uid])
                registeredContacts[uid] = {};
            registeredContacts[uid][email] = "";
            vm.parameters.registeredContacts = registeredContacts;

            if (!vm.connection) {
                preferencesStorage.setContactStatusesParam("query", true);
            }
        };

        function checkRegisteredStatuses() {
            var registeredContacts = vm.parameters.registeredContacts;
            var updated
            for (var id in registeredContacts) {
                for (var email in registeredContacts[id]) {
                    var contact = findContact(email);
                    if (!contact) continue;
                    updated = true;
                    registeredContacts[id][email] = contact.status;
                }
            }

            if (!updated) return;

            vm.parameters.registeredContacts = registeredContacts;
            $rootScope.$broadcast("xmpp.contacts-changed");
        }

        function checkRegisteredStatus(email, status) {
            var registeredContacts = vm.parameters.registeredContacts;

            var updated = false;
            for (var id in registeredContacts) {
                if (registeredContacts[id][email] !== undefined) {
                    registeredContacts[id][email] = status;
                    updated = true;
                    break;
                }
            }

            if (!updated) return;

            vm.parameters.registeredContacts = registeredContacts;
            $rootScope.$broadcast("xmpp.contacts-changed");
        }

        function addRosterContact(contact) {
            if (!contact || !vm.contactCategories) return;
            var foundList;
            for (var i = 0; i < vm.contactCategories.length; i++) {
                var thisList = vm.contactCategories[i];
                if (thisList.name == contact.group) {
                    foundList = thisList;
                    break;
                }
            }
            if (!foundList) {
                foundList = { name: contact.group, open: true, contacts: [] };
                vm.contactCategories.push(foundList);
            }

            // Status room if alias
            foundList.contacts.push({ name: contact.name, jid: contact.bareJid, displayJid: contact.displayJid, status: "offline", unreadCount: 0, pic: contact.pic });
        }

        function removeRosterContact(contact) {
            if (!contact || !vm.contactCategories) return;
            for (var catIndex = 0; catIndex < vm.contactCategories.length; catIndex++) {
                var cat = vm.contactCategories[catIndex];
                for (var contactIndex = 0; contactIndex < cat.contacts.length; contactIndex++) {
                    var c = cat.contacts[contactIndex];
                    if (c.jid == contact.bareJid) {
                        cat.contacts.splice(contactIndex, 1);
                        contactIndex--;
                    }
                }
            }
        }
    }

})();