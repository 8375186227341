(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userCloudStorageConnectController", userCloudStorageConnectController);

	function userCloudStorageConnectController($scope, $mdDialog, $filter, $http, $state, $window, $timeout, modalService, popupService, coreData, errorHandling, showAllOptions) {
		var vm = this;
		$scope.currentType = undefined;
		$scope.returnCode = undefined;
		$scope.displayName = '';
		$scope.isValid = true;
		$scope.isNameValid = true;

		// Functions
		vm.modalServiceCallback = modalServiceCallback;
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.openService = openService;
		$scope.handleWindowClose = handleWindowClose;
		$scope.showAllOptions = showAllOptions;

		activate();

		///////////////////////

		function activate() {
			modalService.setModalCallback(vm.modalServiceCallback);
			$scope.$watch('displayName', function () {
				if ($scope.returnCode) {
					if ($scope.displayName === '') {
						$scope.isNameValid = false;
					} else {
						$scope.isNameValid = true;
						$scope.isValid = true;
					}
				}
			});
		}

		function modalServiceCallback() {
			$mdDialog.cancel({ reason: "cancel" });
		}

		function save() {
			if ($scope.displayName === '') {
				$scope.isNameValid = false;
				$scope.isValid = false;
				return;
			}
			$mdDialog.hide({ name: $scope.displayName, type: $scope.currentType, code: $scope.returnCode });
			modalService.setModalCallback(null);
		}

		function cancel() {
			$mdDialog.cancel({ reason: "cancel" });
			modalService.setModalCallback(null);
		}

		function openService(type) {
			$scope.currentType = undefined;
			$scope.returnCode = undefined;
			var redirect = coreData.baseUrl + "/interface/connect-redirect";
			var params = JSON.stringify({ redirectUri: redirect });
			//The location is set after the post because otherwise some browsers see window.open in async calls as
			// an unwanted popup and block it.
			var newWindow = window.open('', '_blank', popupService.dimensions.connectivity);
			switch (type) {
				case 0: // GOOGLEDRIVE - OBSOLETE
				case 1: // GOOGLEACCESS - OBSOLETE
				case 3: // ONEDRIVE - OBSOLETE
				case 5: // GOOGLE - OBSOLETE
					break;
				case 2: // DROPBOX
					$http.post('~/api/v1/settings/dropbox-redirect', params)
						.then(function (success) {
							$scope.currentType = 2;
							$scope.displayName = $filter('translate')('MY_DROPBOX');
							$window.handleConnectClose = handleWindowClose;
							newWindow.location = success.data.result;
						}, function (failure) {
							errorHandling.report(failure);
							newWindow.close();
						});
					break;
				case 4: // ONEDRIVEV2
					$http.post("~/api/v1/settings/microsoft-redirect/OneDrive", params)
						.then(function (success) {
							$scope.currentType = 4;
							$scope.displayName = $filter("translate")("MY_ONEDRIVE_ACCESS");
							$window.handleConnectClose = handleWindowClose;
							newWindow.location = success.data.result;
						}, function (failure) {
							errorHandling.report(failure);
							newWindow.close();
						});
					break;
				case 7: // Leitzcloud
					$http.post("~/api/v1/settings/leitzcloud-redirect", params)
						.then(
							function (success) {
								$scope.currentType = 7;
								$scope.displayName = $filter("translate")("MY_LEITZCLOUD")
								$window.handleConnectClose = handleWindowClose;
								newWindow.location = success.data.result;
							},
							function (failure) {
								errorHandling.report(failure);
								newWindow.close();
							});
					break;
				case 8: // vBoxx
					$http.post("~/api/v1/settings/vboxx-redirect", params)
						.then(
							function (success) {
								$scope.currentType = 8;
								$scope.displayName = $filter("translate")("MY_VBOXX")
								$window.handleConnectClose = handleWindowClose;
								newWindow.location = success.data.result;
							},
							function (failure) {
								errorHandling.report(failure);
								newWindow.close();
							});
					break;
				case 9: // Google Picker
					params = JSON.stringify({ redirectUri: redirect });
					$http.post("~/api/v1/settings/google-redirect", params)
						.then(function (success) {
							$scope.currentType = 9;
							$scope.displayName = $filter("translate")("MY_GOOGLE_DRIVE");
							$window.handleConnectClose = handleWindowClose;
							newWindow.location = success.data.result;
						}, function (failure) {
							errorHandling.report(failure);
							newWindow.close();
						});
					break;
				default:
					newWindow.close();
					break;
			}
		}

		function handleWindowClose(result) {
			$window.handleConnectClose = undefined;
			if (result.code) {
				$scope.returnCode = result.code;
			}
			//function does not apply scope on its own.
			$scope.$applyAsync();
		}
	}

})();
