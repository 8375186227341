(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataSysAdminSettings", coreDataSysAdminSettings);

	function coreDataSysAdminSettings($http, $q, $translate, $rootScope, preferencesStorage, toaster, errorHandling) {
		var vm = this;
		var _isInitialized = false;
		var _globalMailSettings = null;
		var _localIPs = null;
		var _companyInfo = null;
		var _favicon = null;
		var _systemAdmins = [];
		var _systemMessages = [];
		var _bindingManager = {};
		var _forwardingBlacklist = {};
		var _outgoingGateways = [];
		var _incomingGateways = [];
		var _senderPriorityOverrides = [];
		var _domainArchiveRules = [];
		var _listingCounts = {};
		var _runningMessageArchives = [];
		var listingCountsInitialized = false;
		var _greylist = {};
		var greylistInitialized = false;
		var _blacklist = {};
		var blacklistInitialized = false;
		var _whitelist = {};
		var whitelistInitialized = false;
		var _smtpBlocks = {};
		var smtpBlocksInitialized = false;
		var _spamAssassinServers = {};
		var spamAssassinServersInitialized = false;
		var _abuseDetection = {};
		var abuseDetectionInitialized = false;
		var _honeypotList = {};
		var honeypotListInitialized = false;
		vm.settings = {
			get globalMailSettings() { return _globalMailSettings; },
			set globalMailSettings(value) { _globalMailSettings = value; },
			get localIPs() { return _localIPs; },
			get localIPv4() {
				return $.grep(_localIPs, function (ip) {
					return ip.addressFamily === 2 && ip.address !== "127.0.0.1";
				});
			},
			get localIPv6() {
				return $.grep(_localIPs, function (ip) {
					return ip.addressFamily === 23 && ip.address !== "::1";
				});
			},
			get companyInfo() { return _companyInfo; },
			get favicon() { return _favicon; },
			get systemAdmins() { return _systemAdmins; },
			get systemMessages() { return _systemMessages; },
			get bindingManager() { return _bindingManager; },
			get forwardingBlacklist() { return _forwardingBlacklist; },
			get outgoingGateways() { return _outgoingGateways; },
			get incomingGateways() { return _incomingGateways; },
			get senderPriorityOverrides() { return _senderPriorityOverrides; },
			get domainArchiveRules() { return _domainArchiveRules; },

			get listingCounts() { return getListingCounts(); },

			get blacklist() { return getBlacklist(); },
			set blacklist(value) { _blacklist = value || {}; },

			get whitelist() { return getWhitelist(); },
			set whitelist(value) { _whitelist = value || {}; },

			get smtpBlocks() { return getSmtpBlocks(); },
			set smtpBlocks(value) { _smtpBlocks = value || {}; },

			get spamAssassinServers() { return getSpamAssassinServers(); },
			set spamAssassinServers(value) { _spamAssassinServers = value || {}; },

			get abuseDetection() { return getAbuseDetection(); },
			set abuseDetection(value) { _abuseDetection = value || {}; },

			get honeyPotList() { return getHoneyPotList(); },
			set honeyPotList(value) { _honeypotList = value || {}; },

		};
		var initDefer;
		var getSysAdminNotificationProfilesDefer;

		// Functions
		vm.loadGlobalMailSettings = loadGlobalMailSettings;
		vm.loadCompanyInfo = loadCompanyInfo;
		vm.init = init;
		vm.reset = reset;
		vm.saveGlobalMailSettings = saveGlobalMailSettings;
		vm.saveCompanyInfo = saveCompanyInfo;
		vm.addSystemAdministrator = addSystemAdministrator;
		vm.editSystemAdministrator = editSystemAdministrator;
		vm.addSystemMessage = addSystemMessage;
		vm.saveSystemMessage = saveSystemMessage;
		vm.saveIpAddressBinding = saveIpAddressBinding;
		vm.savePortInfo = savePortInfo;
		vm.saveForwardingBlacklist = saveForwardingBlacklist;
		vm.saveOutgoingGateway = saveOutgoingGateway;
		vm.deleteOutgoingGateways = deleteOutgoingGateways;
		vm.saveIncomingGateways = saveIncomingGateways;
		vm.saveSenderPriorityOverride = saveSenderPriorityOverride;
		vm.deleteSenderPriorityOverride = deleteSenderPriorityOverride;
		vm.saveMessageArchiving = saveMessageArchiving;
		vm.removeFailoverClusterNode = removeFailoverClusterNode;
		vm.showingMessageReindexMessageRef = undefined;
		vm.isMessageArchiveReindexRunning = isMessageArchiveReindexRunning;
		vm.showingMessageReindexMessageTimer = 0;

		activate();

		/////////////////////

		function activate() {
			window.addEventListener('storage', function (event) {
				if (preferencesStorage.getSortingFilteringParam("system", "doLogout")) {
					preferencesStorage.setSortingFilteringParam("system", "doLogout", undefined);
					$rootScope.$broadcast('signalR.mailHub.client.logout', {});
				}
			});

			setTimeout(() => {
				$rootScope.$on("signalR.mailHub.client.messageArchiveReindex", function (e, reindexInfo) {
					//console.log("[MESSAGE REINDEX UPDATE]", `Archive: ${reindexInfo.archivePath} - (${reindexInfo.step}/2) Progress: ${reindexInfo.progress} | ${reindexInfo.currentFile}/${reindexInfo.total} Finished: ${reindexInfo.finished}`, reindexInfo);
					if (!vm.showingMessageReindexMessageRef && !reindexInfo.finished) {
						vm.showingMessageReindexMessageRef = toaster.pop("info", $translate.instant("MESSAGE_ARCHIVE_REINDEXING_TOOLTIP"), "", 0);
					}
					if (!reindexInfo.finished && _runningMessageArchives.indexOf(reindexInfo.archivePath) === -1)
						_runningMessageArchives.push(reindexInfo.domain);

					if (vm.showingMessageReindexMessageTimer > 0)
						clearTimeout(vm.showingMessageReindexMessageTimer);

					if (reindexInfo.finished) {
						if (vm.showingMessageReindexMessageRef) {
							toaster.clear(vm.showingMessageReindexMessageRef);
							clearTimeout(vm.showingMessageReindexMessageTimer);
							vm.showingMessageReindexMessageRef = undefined;
						}
						if (_runningMessageArchives.indexOf(reindexInfo.archivePath) > -1) {
							_runningMessageArchives.splice(_runningMessageArchives.indexOf(reindexInfo.domain));
						}
					} else {
						vm.showingMessageReindexMessageTimer = setTimeout(() => {
							if (vm.showingMessageReindexMessageRef) {
								toaster.clear(vm.showingMessageReindexMessageRef);
								vm.showingMessageReindexMessageRef = undefined;
							}

						}, 20000);
					}
				});
			}, 500);

		}

		function init() {

			if (initDefer)
				return initDefer.promise;

			if (_isInitialized)
				return $q.when();

			initDefer = $q.defer();
			var promise = initDefer.promise;

			var promises = [];
			promises.push(loadGlobalMailSettings());
			promises.push(loadIPs());
			promises.push(loadCompanyInfo());
			promises.push(loadSystemAdminstrators());
			promises.push(loadSystemMessages());
			promises.push(loadBindings());
			promises.push(loadForwardingBlacklist());
			promises.push(loadOutgoingGateways());
			promises.push(loadIncomingGateways());
			promises.push(loadSenderPriorityOverrides());
			promises.push(loadDomainArchiveRules());
			$q.all(promises)
				.then(function () {
					_isInitialized = true;
					initDefer.resolve();
				}, function (failure) {
					initDefer.reject(failure);
				})
				.finally(function () {
					preferencesStorage.setSortingFilteringParam("system", "doLogout", undefined);
					sessionStorage.rememberMe = "false";
					initDefer = null;
				});

			return promise;
		}

		function reset() {
			_isInitialized = false;
			_listingCounts = {};
			listingCountsInitialized = false;
			_greylist = {};
			greylistInitialized = false;
			_blacklist = {};
			blacklistInitialized = false;
			_whitelist = {};
			whitelistInitialized = false;
			_globalMailSettings = [];
			_smtpBlocks = {};
			smtpBlocksInitialized = false;
			_spamAssassinServers = {};
			spamAssassinServersInitialized = false;
			_abuseDetection = {};
			abuseDetectionInitialized = false;
			_honeypotList = {};
			honeypotListInitialized = false;
		}

		function loadGlobalMailSettings() {
			return $http.get("~/api/v1/settings/sysadmin/global-mail")
				.then(function (success) {
					_globalMailSettings = success.data.globalMailSettings;
				}, function (failure) {
					_globalMailSettings = null;
				});
		}

		function saveGlobalMailSettings(changes) {
			if (!changes) return $q.when();
			var params = JSON.stringify({
				globalMailSettings: changes
			});
			return $http.post("~/api/v1/settings/sysadmin/global-mail/", params);
		}

		function loadIPs() {
			return $http.get("~/api/v1/settings/sysadmin/ips")
				.then(function (success) {
					_localIPs = success.data.ips;
				}, function (failure) {
					_localIPs = null;
				});
		}

		function loadCompanyInfo() {
			return $http.get("~/api/v1/companyinfo")
				.then(function (success) {
					_companyInfo = success.data.companyInfo;
				}, function (failure) {
					_companyInfo = null;
				});
		}

		function saveCompanyInfo(newCompanyInfo) {
			if (!newCompanyInfo) return $q.when();
			var params = JSON.stringify({
				companyInfo: newCompanyInfo
			});
			return $http.post("~/api/v1/companyinfo", params);
		}

		function loadSystemAdminstrators() {
			return $http
				.get("~/api/v1/settings/sysadmin/logins")
				.then(function (success) {
					angular.forEach(success.data.systemLogins, function (admin) {
						admin.description = admin.description || "";
					});
					_systemAdmins = success.data.systemLogins;
				}, function () {
					_systemAdmins = [];
				});
		}

		function addSystemAdministrator(sysAdminInfo, password) {
			var params = JSON.stringify({
				systemLogin: sysAdminInfo,
				confirmPassword: password
			});
			var addDefer = $q.defer();
			$http
				.post("~/api/v1/settings/sysadmin/login-post", params)
				.then(onSuccess, onAddSysAdminFail);

			function onSuccess(success) {
				_systemAdmins.push(success.data.systemLogin);
				addDefer.resolve(success.data.systemLogin);
			}
			function onAddSysAdminFail(result) {

				addDefer.reject(result);
			}
			return addDefer.promise;
		}

		function editSystemAdministrator(sysAdminInfo, password) {
			var params = JSON.stringify({
				systemLogin: sysAdminInfo,
				confirmPassword: password
			});
			return $http
				.post("~/api/v1/settings/sysadmin/login", params)
				.then(onSuccess, errorHandling.report);

			function onSuccess() {
				var temp = $.grep(_systemAdmins, function (sa) {
					return sysAdminInfo.id === sa.id;
				});
				if (temp.length > 0) {
					var index = _systemAdmins.indexOf(temp[0]);
					if (index > -1) {
						_systemAdmins[index] = sysAdminInfo;
					}
				}
			}
		}

		function addSystemMessage(info) {
			if (!info) return $q.when();
			var params = JSON.stringify(info);
			return $http.post("~/api/v1/settings/sysadmin/system-message-new", params)
				.then(function () {
					_systemMessages.push(info);
				});
		}

		function loadSystemMessages() {
			return $http
				.get("~/api/v1/settings/sysadmin/system-messages")
				.then(function (success) {
					_systemMessages = success.data.systemMessages;
				}, function (failure) {
					_systemMessages = [];
				});
		}

		function saveSystemMessage(info) {
			if (!info) return $q.when();
			var params = JSON.stringify(info);
			return $http.post("~/api/v1/settings/sysadmin/system-message", params)
				.then(function () {
					var temp = $.grep(_systemMessages, function (msg) {
						return msg.key === info.key && msg.localeId === info.localeId;
					});
					if (temp.length > 0) {
						var index = _systemMessages.indexOf(temp[0]);
						if (index > -1) {
							_systemMessages[index] = info;
						}
					}
				});
		}

		function loadBindings() {
			return $http.get("~/api/v1/settings/sysadmin/ip-binding-manager")
				.then(function (success) {
					_bindingManager = success.data.bindingManager;
				}, function (failure) {
					_bindingManager = {};
				});
		}

		function saveIpAddressBinding(info) {
			if (!info) return $q.when();
			var params = JSON.stringify({
				bindingInfo: [info]
			});
			return $http.post("~/api/v1/settings/sysadmin/ip-binding-info", params)
				.then(function () {
					var temp = $.grep(_bindingManager.bindingInfo, function (bindingInfo) {
						return bindingInfo.ipAddress === info.ipAddress;
					});
					if (temp.length > 0) {
						var index = _bindingManager.bindingInfo.indexOf(temp[0]);
						if (index > -1) {
							_bindingManager.bindingInfo[index] = info;
						}
					}
				});
		}

		function savePortInfo(info, toAdd, toRemove) {
			var params = {};
			if (info) params.bindingPorts = [info];
			if (toAdd) params.toAdd = [toAdd];
			if (toRemove) params.toRemove = toRemove;
			params = JSON.stringify(params);
			return $http.post("~/api/v1/settings/sysadmin/ip-binding-ports", params)
				.then(function (success) {
					if (info) {
						var temp = $.grep(_bindingManager.bindingPorts, function (port) {
							return port.id === info.id;
						});
						if (temp.length > 0) {
							var index = _bindingManager.bindingPorts.indexOf(temp[0]);
							if (index > -1) {
								_bindingManager.bindingPorts[index] = info;
							}
						}
					}
					if (toRemove && angular.isArray(toRemove)) {
						_bindingManager.bindingPorts = $.grep(_bindingManager.bindingPorts, function (port) {
							return toRemove.indexOf(port.id) > -1;
						}, true);
					}
					if (toAdd && success.data.bindingPorts) {
						_bindingManager.bindingPorts = _bindingManager.bindingPorts.concat(success.data.bindingPorts);
					}
				});
		}

		function loadForwardingBlacklist() {
			return $http.get("~/api/v1/settings/sysadmin/forward-blacklist")
				.then(function (success) {
					_forwardingBlacklist = success.data.forwardBlacklists;
				}, function (failure) {
					_forwardingBlacklist = {};
				});
		}

		function saveForwardingBlacklist(domains, toRemove) {
			var params = JSON.stringify({
				forwardBlacklists: domains,
				toRemove: toRemove
			});
			return $http.post("~/api/v1/settings/sysadmin/forward-blacklist", params)
				.then(function (success) {
					if (domains) _forwardingBlacklist = domains;
					if (toRemove) {
						angular.forEach(toRemove, function (t) {
							var index = _forwardingBlacklist.indexOf(t);
							if (index > -1)
								_forwardingBlacklist.splice(index, 1);
						});
					}
				})
		}

		function loadOutgoingGateways() {
			return $http.get("~/api/v1/settings/sysadmin/gateways")
				.then(function (success) {
					_outgoingGateways = success.data.gatewayConfigs || [];
				}, function (failure) {
					_outgoingGateways = [];
				});
		}

		function saveOutgoingGateway(gateway) {
			if (!gateway) return;
			// Swap priority levels, if necessary
			if (gateway.priorityLow > gateway.priorityHigh) {
				var temp = gateway.priorityLow;
				gateway.priorityLow = gateway.priorityHigh;
				gateway.priorityHigh = temp;
			}
			var params = JSON.stringify({
				gatewayConfig: gateway
			});
			if (gateway.id) {
				return $http.post("~/api/v1/settings/sysadmin/gateway", params)
					.then(function (success) {
						var temp = $.grep(_outgoingGateways, function (g) { return g.id === gateway.id; });
						if (temp.length > 0) {
							var index = _outgoingGateways.indexOf(temp[0]);
							if (index > -1)
								_outgoingGateways[index] = gateway;
						}
					});
			} else {
				return $http.post("~/api/v1/settings/sysadmin/gateway-put", params)
					.then(function (success) {
						gateway.id = success.data.id;
						_outgoingGateways.push(gateway);
					});
			}
		}

		function deleteOutgoingGateways(gatewayIds) {
			if (!angular.isArray(gatewayIds)) return;
			var params = JSON.stringify({ ids: gatewayIds });
			return $http.post("~/api/v1/settings/sysadmin/gateways/delete", params)
				.then(function () {
					angular.forEach(gatewayIds, function (id) {
						var temp = $.grep(_outgoingGateways, function (g) { return g.id === id; });
						if (temp.length > 0) {
							var index = _outgoingGateways.indexOf(temp[0]);
							if (index > -1) {
								_outgoingGateways.splice(index, 1);
							}
						}
					});
				});
		}

		function loadIncomingGateways() {
			return $http.get("~/api/v1/settings/sysadmin/smart-host-servers")
				.then(function (success) {
					_incomingGateways = success.data.smartHosts || [];
				}, function (failure) {
					_incomingGateways = [];
				});
		}

		function saveIncomingGateways(editedGateway, toAdd, toRemove) {
			var gateways = angular.copy(_incomingGateways);
			if (editedGateway) {
				var temp = $.grep(gateways, function (g) { return g.id === editedGateway.id; });
				if (temp.length > 0) {
					var index = gateways.indexOf(temp[0]);
					if (index > -1)
						gateways.splice(index, 1);
				}
				gateways.push(editedGateway);
			}
			var params = JSON.stringify({
				smartHosts: gateways,
				toAdd: toAdd,
				toRemove: toRemove
			});
			return $http.post("~/api/v1/settings/sysadmin/smart-host-servers", params)
				.then(function (success) {
					if (editedGateway) {
						var temp = $.grep(_incomingGateways, function (ic) { return ic.id === editedGateway.id; });
						if (temp.length > 0) {
							var index = _incomingGateways.indexOf(temp[0]);
							if (index > -1) {
								_incomingGateways[index] = editedGateway;
							}
						}
					}

					if (toAdd) {
						_incomingGateways = _incomingGateways.concat(success.data.smartHosts || []);
					}

					if (toRemove) {
						angular.forEach(toRemove, function (g) {
							var temp = $.grep(_incomingGateways, function (ic) { return ic.id === g; });
							if (temp.length > 0) {
								var index = _incomingGateways.indexOf(temp[0]);
								if (index > -1)
									_incomingGateways.splice(index, 1);
							}
						});
					}

				});
		}

		function loadSenderPriorityOverrides() {
			return $http.get("~/api/v1/settings/sysadmin/priority-overrides")
				.then(function (success) {
					_senderPriorityOverrides = success.data.priorityOverrides || [];
				}, function (failure) {
					_senderPriorityOverrides = [];
				});
		}

		function saveSenderPriorityOverride(address, info, isNew) {
			if (!info || (!isNew && !address)) return;
			var params = JSON.stringify({
				priorityOverride: info
			});
			if (isNew) {
				return $http.post("~/api/v1/settings/sysadmin/priority-override-put", params)
					.then(function () {
						_senderPriorityOverrides.push(info);
					});
			} else {
				return $http.post("~/api/v1/settings/sysadmin/priority-override/" + address, params)
					.then(function () {
						var temp = $.grep(_senderPriorityOverrides, function (spo) { return spo.address === address; });
						if (temp.length > 0) {
							var index = _senderPriorityOverrides.indexOf(temp[0]);
							if (index > -1)
								_senderPriorityOverrides[index] = info;
						}
					});
			}
		}

		function deleteSenderPriorityOverride(addresses) {
			if (!angular.isArray(addresses)) return;
			var params = JSON.stringify({ addresses: addresses });
			return $http.post("~/api/v1/settings/sysadmin/priority-overrides/delete", params)
				.then(function () {
					angular.forEach(addresses, function (address) {
						var temp = $.grep(_senderPriorityOverrides, function (spo) { return spo.address === address });
						if (temp.length > 0) {
							var index = _senderPriorityOverrides.indexOf(temp[0]);
							if (index > -1) {
								_senderPriorityOverrides.splice(index, 1);
							}
						}
					});
				});
		}

		function loadDomainArchiveRules() {
			return $http.get("~/api/v1/settings/sysadmin/domain-archive-rules")
				.then(function (success) {
					_domainArchiveRules = success.data.rules;
					angular.forEach(_domainArchiveRules, function (rule) {
						rule.allDomains = rule.domain === "GlobalStrings.AllDomains" || rule.domain === "ALL_DOMAINS";
						if (rule.allDomains)
							rule.domain = "GlobalStrings.AllDomains";
					});
				}, function (failure) {
					_domainArchiveRules = [];
				});
		}

		function saveMessageArchiving(rules, toAdd, toRemove) {
			for (var i = 0; i < rules.length; ++i) {
				if (rules[i].domain === "ALL_DOMAINS") {
					rules[i].domain = "GlobalStrings.AllDomains";
					break;
				}
			}
			var params = JSON.stringify({
				rules: rules,
				toAdd: toAdd,
				toRemove: toRemove
			});
			return $http.post("~/api/v1/settings/sysadmin/domain-archive-rules", params)
				.then(function (success) {
					// Add
					if (angular.isArray(toAdd))
						_domainArchiveRules = _domainArchiveRules.concat(toAdd);

					// Edit
					if (angular.isArray(rules)) {
						angular.forEach(rules, function (rule) {
							if (rule.domain === "GlobalStrings.AllDomains")
								rule.domain = "ALL_DOMAINS";
							var temp = $.grep(_domainArchiveRules, function (r) { return r.domain === rule.domain; });
							if (temp.length > 0) {
								var index = _domainArchiveRules.indexOf(temp[0]);
								if (index > -1)
									_domainArchiveRules.splice(index, 1);
							}
						});
						_domainArchiveRules = _domainArchiveRules.concat(rules);
					}

					// Remove
					if (angular.isArray(toRemove)) {
						angular.forEach(toRemove, function (rule) {
							var temp = $.grep(_domainArchiveRules, function (d) { return d.domain === rule; });
							if (temp.length > 0) {
								var index = _domainArchiveRules.indexOf(temp[0]);
								if (index > -1)
									_domainArchiveRules.splice(index, 1);
							}
						});
					}
				});
		}
		function isMessageArchiveReindexRunning(archiveDomain) {
			return _runningMessageArchives.indexOf(archiveDomain) > -1
		}

		function removeFailoverClusterNode(ips) {
			if (!angular.isArray(ips)) return $q.when();
			var params = JSON.stringify({
				ips: ips
			});
			return $http.post("~/api/v1/settings/sysadmin/delete-failover-nodes", params)
				.then(function () {
					angular.forEach(ips, function (ip) {
						var temp = $.grep(_globalMailSettings.failoverClusterSettings.failoverNodes || [], function (node) { return node.ipAddress === ip; });
						if (temp.length > 0) {
							var index = _globalMailSettings.failoverClusterSettings.failoverNodes.indexOf(temp[0]);
							if (index > -1) {
								_globalMailSettings.failoverClusterSettings.failoverNodes.splice(index, 1);
							}
						}
					});
				});
		}

		function loadListingCounts() {
			return $http.get('~/api/v1/settings/sysadmin/listing-counts')
				.then(function (success) {
					_listingCounts = success.data;
					listingCountsInitialized = true;
				}, function (failure) {
					_listingCounts = {};
					listingCountsInitialized = false;
				});
		}

		function getListingCounts() {
			var defer = $q.defer();
			if (listingCountsInitialized) {
				return $q.when(_listingCounts);
			}
			loadListingCounts()
				.then(function (success) {
					defer.resolve(_listingCounts);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_LISTING_COUNTS");
				});

			return defer.promise;
		};

		function loadBlacklist() {
			return $http.get('~/api/v1/settings/sysadmin/ip-access/' + false)
				.then(function (success) {
					_blacklist = success.data.ipAccessList;
					blacklistInitialized = true;
				}, function (failure) {
					_blacklist = {};
					blacklistInitialized = false;
				});
		}

		function getBlacklist() {
			var defer = $q.defer();
			if (blacklistInitialized) {
				return $q.when(_blacklist);
			}
			loadBlacklist()
				.then(function (success) {
					defer.resolve(_blacklist);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_BLACKLIST");
				});

			return defer.promise;
		}

		function loadWhitelist() {
			return $http.get('~/api/v1/settings/sysadmin/ip-access/' + true)
				.then(function (success) {
					_whitelist = success.data.ipAccessList;
					whitelistInitialized = true;
				}, function (failure) {
					_whitelist = {};
					whitelistInitialized = false;
				});
		}

		function getWhitelist() {
			var defer = $q.defer();
			if (whitelistInitialized) {
				return $q.when(_whitelist);
			}
			loadWhitelist()
				.then(function (success) {
					defer.resolve(_whitelist);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_BLACKLIST");
				});

			return defer.promise;
		}

		function loadHoneypotList() {
			return $http.get('~/api/v1/settings/sysadmin/honey-pot/list')
				.then(function (success) {
					_honeypotList = success.data.entries;
					honeypotInitialized = true;
				}, function (failure) {
					_honeypotList = {};
					honeypotInitialized = false;
				});
		}

		function getHoneyPotList() {
			var defer = $q.defer();
			if (honeypotInitialized) {
				return $q.when(_honeypotList);
			}
			loadHoneypotList()
				.then(function (success) {
					defer.resolve(_honeypotList);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_BLACKLIST");
				});

			return defer.promise;
		}

		function loadSmtpBlocks() {
			return $http.get('~/api/v1/settings/sysadmin/smtp-block-rules')
				.then(function (success) {
					_smtpBlocks = success.data.blockRules;
					smtpBlocksInitialized = true;
				}, function (failure) {
					_smtpBlocks = {};
					smtpBlocksInitialized = false;
				});
		}

		function getSmtpBlocks() {
			var defer = $q.defer();
			if (smtpBlocksInitialized) {
				return $q.when(_smtpBlocks);
			}
			loadSmtpBlocks()
				.then(function (success) {
					defer.resolve(_smtpBlocks);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_SMTP_AUTH_BYPASS_LIST");
				});

			return defer.promise;
		};

		function loadSpamAssassinServers() {
			return $http.get('~/api/v1/settings/sysadmin/spam-assassin-servers')
				.then(function (success) {
					_spamAssassinServers = success.data.spamAssassinServers;
					spamAssassinServersInitialized = true;
				}, function (failure) {
					_spamAssassinServers = {};
					spamAssassinServersInitialized = false;
				});
		}

		function getSpamAssassinServers() {
			var defer = $q.defer();
			if (spamAssassinServersInitialized) {
				return $q.when(_spamAssassinServers);
			}
			loadSpamAssassinServers()
				.then(function (success) {
					defer.resolve(_spamAssassinServers);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_SPAM_ASSASSIN_SERVERS");
				});

			return defer.promise;
		}

		function loadAbuseDetection() {
			return $http.get('~/api/v1/settings/sysadmin/temp-ip-blocks')
				.then(function (success) {
					_abuseDetection = success.data.tempIPBlocks;
					abuseDetectionInitialized = true;
				}, function (failure) {
					_abuseDetection = {};
					abuseDetectionInitialized = false;
				});
		}

		function getAbuseDetection() {
			var defer = $q.defer();
			if (abuseDetectionInitialized) {
				return $q.when(_abuseDetection);
			}
			loadAbuseDetection()
				.then(function (success) {
					defer.resolve(_abuseDetection);
				}, function (failure) {
					defer.reject("ERROR_FAILED_TO_LOAD_ABUSE_DETECTION");
				});

			return defer.promise;
		}

	}

})();