(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('topMenuBarUser', {
            templateUrl: "app/root/top-menu-bar.component.html",
            controller: topMenuBarController,
            transclude: false,
            bindings: {}
        });

    /* @ngInject */
    function topMenuBarController($rootScope, $scope, $log, $state, $transitions, browserNotifications, $http, $mdDialog, $filter, $window, $mdMenu, $stateParams,
        $interval, $timeout, coreData, coreDataSettings, coreDataSysAdminSettings, userDataService, coreLicensing, errorHandling, AuthenticationService, notifications,
        coreDataMail, claimsService, simpleXmppService, authStorage, treeState, preferencesStorage, popupService, themesService, $translate) {
        var vm = this;
        var initialTheme = (themesService.isThemeDark()) || false;
        var debouncedOnResize = _.throttle(onResize, 50, { leading: false });
        var statusUpdateInterval;

        vm.chatStatus = '';//simpleXmppService.parameters.status;
        vm.hasChat = true;

        // The top navigation menu
        vm.menuEmail = { text: 'EMAIL', translated: $translate.instant('EMAIL'), sref: 'index.email' };
        vm.menuCalendar = { text: 'CALENDAR', translated: $translate.instant('CALENDAR'), sref: 'index.calendar' };
        vm.menuContacts = { text: 'CONTACTS', translated: $translate.instant('CONTACTS'), sref: 'index.contacts' }
        vm.menuTasks = { text: 'TASKS', translated: $translate.instant('TASKS'), sref: 'index.tasks' };
        vm.menuNotes = { text: 'NOTES', translated: $translate.instant('NOTES'), sref: 'index.notes' };
        vm.menuWorkspaces = { text: 'ONLINE_MEETINGS', translated: $translate.instant('ONLINE_MEETINGS'), sref: 'index.meetings' };
        vm.menuNewsFeeds = { text: 'RSS_FEEDS', translated: $translate.instant('RSS_FEEDS'), sref: 'index.rss-feeds' };
        vm.menuFileStorage = { text: 'FILE_STORAGE', translated: $translate.instant('FILE_STORAGE'), sref: 'index.file-storage' };
        //vm.menuAdvancedSearch = { text: 'ADVANCED_SEARCH', translated: $translate.instant('ADVANCED_SEARCH'), sref: 'index.advanced-search' };
        vm.menuReports = { text: 'REPORTS', translated: $translate.instant('REPORTS'), sref: 'index.reports.reports-summary', parent_sref: 'index.reports', sref_params: { type: 'user' } };
        vm.menuSettings = { text: 'SETTINGS', translated: $translate.instant('SETTINGS'), dividerBefore: true, sref: 'index.settings.user-account-settings', parent_sref: 'index.settings', sref_params: { type: 'user' } };
        vm.menuDomainReports = { text: 'DOMAIN_REPORTS', translated: $translate.instant('DOMAIN_REPORTS'), dividerBefore: true, hidden: !coreData.user.isDomainAdmin, sref: 'index.reports.domain-reports-summary', parent_sref: 'index.reports', sref_params: { type: 'domain' } };
        vm.menuDomainSettings = { text: 'DOMAIN_SETTINGS', translated: $translate.instant('DOMAIN_SETTINGS'), hidden: !coreData.user.isDomainAdmin, sref: 'index.settings.domain-users', parent_sref: 'index.settings', sref_params: { type: 'domain' } };
        vm.menuNormal = {
            expanded: [
                vm.menuEmail,
                vm.menuCalendar,
                vm.menuContacts,
                vm.menuTasks
            ],
            dropdown: [
                vm.menuNotes,
                vm.menuWorkspaces,
                vm.menuNewsFeeds,
                vm.menuFileStorage,
                vm.menuReports,
                //vm.menuAdvancedSearch,
                vm.menuSettings,
                vm.menuDomainReports,
                vm.menuDomainSettings
            ],
        }
        vm.menuNarrowDisplays = {
            expanded: [],
            dropdown: [
                vm.menuEmail,
                vm.menuCalendar,
                vm.menuContacts,
                vm.menuTasks,
                vm.menuNotes,
                vm.menuWorkspaces,
                vm.menuNewsFeeds,
                vm.menuFileStorage,
                vm.menuReports,
                //vm.menuAdvancedSearch,
                vm.menuSettings,
                vm.menuDomainReports,
                vm.menuDomainSettings
            ],
        }
        vm.menu = vm.menuNormal;
        vm.dropdownTitle = $translate.instant("MORE");
        vm.isDropdownMenuHilighted = false;

        vm.addMenuOnlineMeeting = { text: 'ONLINE_MEETING', popup: 'meeting', icon: 'toolsicon-groups', hidden: true };
        vm.addMenuItems = [
            { text: 'EMAIL', popup: 'email', icon: 'toolsicon-mail' },
            { text: 'APPOINTMENT', popup: 'appointment', icon: 'toolsicon-calendar' },
            { text: 'CONTACT', popup: 'contact', icon: 'toolsicon-person' },
            { text: 'TASK', popup: 'task', icon: 'toolsicon-check' },
            { text: 'NOTE', popup: 'note', icon: 'toolsicon-description' },
            vm.addMenuOnlineMeeting,
        ];

        vm.rightItems = [
            vm.rightItemSearch = { text: 'ADVANCED_SEARCH', icon: 'search', counter: 0, click: openAdvancedSearch },
            vm.rightItemChat = { text: 'CHAT', icon: 'chat', counter: 0, click: function () { simpleXmppService.popout("recent"); /*$state.go("index.chat");*/ } },
            vm.rightItemNotifications = { text: 'NOTIFICATIONS', icon: 'bell', counter: 0, click: function () { window.open((stSiteRoot || '/') + "interface/root#/popout/notifications", "notifications", 'resizable=1, ' + popupService.dimensions.notification); } },
            vm.rightItemTheme = {
                text: initialTheme ? 'SWITCH_TO_LIGHT_THEME' : 'SWITCH_TO_DARK_THEME',
                icon: initialTheme ? 'dark_mode' : 'light_mode_filled', click: function () { toggleTheme(this); }
            }
        ];

        vm.avatarClick = function () { }
        vm.avatarGuid = (new Date()).getTime();
        vm.avatarName = coreData.user.displayName;
        vm.avatarUrl = coreData.user.pictureUrl;
        vm.currentItem = null;
        vm.edition = 1;
        vm.customHelpInfo = null;
        vm.userNotificationCountIndicator = 0;
        vm.unreadMessages = 0;
        vm.user = coreData.user;
        vm.isReady = false;
        vm.treeState = treeState;

        // Functions
        vm.toggleTree = toggleTree;
        vm.openSectionMenu = openSectionMenu;
        vm.openHelp = openHelp;
        vm.openCustomHelp = openCustomHelp;
        vm.openAbout = openAbout;
        vm.logout = logout;
        vm.navTo = navTo;
        vm.setChatStatus = setChatStatus;
        vm.openAddMenuItem = openAddMenuItem;
        vm.openActiveSectionAddMenuItem = openActiveSectionAddMenuItem;

        activate();

        /////////////////

        function activate() {
            onWindowWidthChanged();

            $scope.$on('user-settings:changed', function () {
                vm.avatarName = coreData.user.displayName;
                vm.avatarUrl = coreData.user.pictureUrl;
            });

            window.addEventListener('resize', debouncedOnResize);
            $scope.$on("$destroy", function () {
                window.removeEventListener('resize', debouncedOnResize);
            });

            $transitions.onStart({}, stateChanged);
            $transitions.onSuccess({}, stateSuccess);
            stateChanged();
            doCheckItemActive($state.current.name, $state.href($state.current.name, $state.params, { absolute: false }));

            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            userDataService
                .init()
                .then(function () {
                    vm.features = userDataService.user.settings.features;
                    recalculateLicense();

                    vm.user = coreData.user;
                    vm.avatarName = coreData.user.displayName;
                    vm.avatarUrl = coreData.user.pictureUrl;
                    vm.menuFileStorage.hidden = !vm.features.enableFileStorage && !vm.features.enableCloudStorage;

                    vm.customHelpInfo = coreData.customHelpInfo;
                    if (vm.customHelpInfo && (!vm.customHelpInfo.url || vm.customHelpInfo.url == 'http://' || vm.customHelpInfo.url == 'https://'))
                        vm.customHelpInfo = null;

                    // In case items are not in the DOM yet, wait to do initial resizing until after they show
                    const initialResizeInterval = setInterval(initialResizeCheck, 25);
                    function initialResizeCheck() {
                        var testHtml = $('#hamburger-toggle');
                        if (testHtml && testHtml.length > 0 && testHtml.width()) {
                            onResize();
                            clearInterval(initialResizeInterval);
                        }
                    }
                }, function () { });

            refreshNotifications();
            hookEvents();
            runStatusInterval();
        }

        function runStatusInterval() {
            clearInterval(statusUpdateInterval);
            statusUpdateInterval = setInterval(function () {
                if (vm.chatStatus !== 'connecting' && vm.chatStatus) {
                    clearInterval(statusUpdateInterval);
                    return;
                }

                if (simpleXmppService.connected) {
                    if (vm.chatStatus !== simpleXmppService.parameters.status) {
                        $scope.$applyAsync(function () {
                            vm.chatStatus = simpleXmppService.parameters.status;
                        });
                        clearInterval(statusUpdateInterval);
                    }
                }
            }, 100);
        }

        function onResize(event) {
            onWindowWidthChanged();
        }

        function onWindowWidthChanged() {
            const topBar = document.querySelector('.root-heading');
            const barWidth = topBar.getBoundingClientRect().width;
            if (!barWidth)
                return;

            // Find width of all items that stay visible
            var fixedItemsWidth = 0;
            var fixedItems = [];
            const fixedItemSelectors = [
                '#hamburger-toggle',
                '#add-button',
                '.right-item',
                '#right-most-menu',
            ];
            topBar
                .querySelectorAll(fixedItemSelectors.join(', '))
                .forEach((item) => {
                    // We don't exclude hidden items so that things don't shift if they suddenly appear
                    fixedItems.push({ id: item.id, classList: item.classList.value, width: item.getBoundingClientRect().width })
                    fixedItemsWidth += item.getBoundingClientRect().width;
                });

            // Calculate the size of each default item, and use it to figure out if we should shrink the menu
            var remainingWidth = barWidth - fixedItemsWidth;
            vm.menuNormal.expanded.forEach(item => {
                const width = testWidth($translate.instant(item.text), item === vm.menuNormal.expanded[0]);
                remainingWidth -= width;
            });

            const sizeToReserveForAppMenu = vm.menuNormal.dropdown.length > 0 ? 300 : 0;
            const newMenu = barWidth < 736 || remainingWidth < sizeToReserveForAppMenu ? vm.menuNarrowDisplays : vm.menuNormal;
            if (vm.menu !== newMenu || !vm.isReady) {
                $scope.$applyAsync(function () {
                    vm.menu = newMenu;
                    vm.isReady = true;
                    recalulateDropdownMenuClassesInsideScope();
                });
            }

            function testWidth(name, first) {
                const div = document.createElement("DIV");
                div.style.visibility = 'hidden';
                div.classList.add('left-item');
                div.classList.toggle('left-most', first);

                const title = document.createElement("DIV");
                title.classList.add('section-title');
                title.innerText = name;
                div.appendChild(title);

                topBar.appendChild(div);
                const itemWidth = div.getBoundingClientRect().width;
                topBar.removeChild(div);
                return itemWidth;
            }
        }

        function hookEvents() {
            $scope.$on("xmpp.property-changed", onXmppPropertyChanged);
            $scope.$on('signalR.mailHub.client.notificationChanged', onNotificationChanged);
            $scope.$on('signalR.mailHub.client.eventNotification', onEventNotification);
            $scope.$on('signalR.mailHub.client.appointmentReminderNotification', onAppointmentReminderNotification);
            $scope.$on('signalR.mailHub.client.mailAdded', onMailCountChanged);
            $scope.$on('signalR.mailHub.client.mailRemoved', onMailCountChanged);
            $scope.$on('signalR.mailHub.client.mailModified', onMailCountChanged);
            $scope.$on('signalR.mailHub.client.folderChange', onMailCountChanged);
            $scope.$on('signalR.mailHub.client.messageFlagChange', onMailCountChanged);
            $scope.$on('mail:unreadCountChanged', onMailCountChanged);
            $scope.$on("treeState:stateChange", onTreeStateChanged);
            $rootScope.$on("loadCustomHelp", refreshCustomHelp);
            $rootScope.$on("themeService:changed", onThemeUpdated);
        }

        function onThemeUpdated() {
            $scope.$applyAsync(function () {
                vm.rightItemTheme.icon = themesService.isThemeDark() ? 'dark_mode' : 'light_mode_filled';
                vm.rightItemTheme.text = themesService.isThemeDark() ? 'SWITCH_TO_LIGHT_THEME' : 'SWITCH_TO_DARK_THEME';

            });
        }

        function toggleTheme(sender) {
            themesService.togglePrimaryTheme();
        }

        function onTreeStateChanged(event, stateData) {
        }

        function onMailCountChanged() {
            $scope.unreadMessages = coreDataMail.getUnreadCount();
            $rootScope.unreadEmailCount = $scope.unreadMessages;
            $rootScope.$broadcast('unreadMailChanged');
            vm.unreadMessages = $scope.unreadMessages;
            vm.menuEmail.counter = vm.unreadMessages;
            $scope.$evalAsync();
        }

        function onNotificationChanged(event, data) {
            if (data.userCount !== null && data.userCount !== undefined) {
                $scope.userNotificationCount = data.userCount;
                vm.userNotificationCount = data.userCount;
            }
            if (data.domainCount !== null && data.domainCount !== undefined) {
                $scope.domainNotificationCount = data.domainCount;
                vm.domainNotificationCount = data.domainCount;
            }
            if (data.systemCount !== null && data.systemCount !== undefined) {
                $scope.systemNotificationCount = data.systemCount;
                vm.systemNotificationCount = data.systemCount;
            }
            vm.userNotificationCountIndicator = vm.userNotificationCount + vm.domainNotificationCount;
            vm.rightItemNotifications.counter = vm.userNotificationCountIndicator;
            $scope.$evalAsync();
        }

        function onEventNotification(event, data) {
            var options = {
                body: $(data.Text).text(),
                icon: (stSiteRoot || '/') + 'interface/img/notifications/alert.png',
                timeout: 5000,
                tag: data.Guid
            };
            options.notifyClick = function (e) {
                window.open((stSiteRoot || '/') + "interface/root#/popout/notifications", "notifications", 'resizable=1,' + popupService.dimensions.notification);
            }
            browserNotifications.show(data.subject, options);
        }

        function onAppointmentReminderNotification(event, data) {
            var options = {
                body: data.text,
                icon: (stSiteRoot || '/') + 'interface/img/notifications/calendar.png',
                timeout: 5000,
                tag: data.guid,
            };
            options.notifyClick = function (e) {
                window.open((stSiteRoot || '/') + "interface/root#/popout/notifications", "notifications", 'resizable=1,' + popupService.dimensions.notification);
            }
            if (coreDataSettings.userSettings.notifyOnCalendarReminders)
                browserNotifications.show(data.subject, options);
        }

        function onXmppPropertyChanged(event, args) {
            if (args.hasOwnProperty("status")) {
                $scope.chatStatus = args.status;
                vm.chatStatus = $scope.chatStatus;
            }
            if (args.hasOwnProperty("unreadCount")) {
                $scope.chatUnreadMessages = args.unreadCount;
                vm.chatUnreadMessages = $scope.chatUnreadMessages;
            }
            vm.rightItemChat.counter = vm.chatUnreadMessages;
            $scope.$evalAsync();
        }

        function setChatStatus(newStatus) {
            if (vm.chatStatus == 'connecting') {
                return;
            }

            simpleXmppService.setStatus(newStatus);

            if (vm.chatStatus == 'offline' && newStatus != 'offline') {
                vm.chatStatus = "connecting";
                runStatusInterval();
            }

        }

        function stateChanged(trans) {
            var id = trans ? trans.$to().name : $state.current.name;
            var params = $stateParams;
            angular.forEach([...vm.menu.expanded, ...vm.menu.dropdown], function (item) {
                var itemSref = item.parent_sref ? item.parent_sref : item.sref;
                if (itemSref !== id && id.indexOf(itemSref) === -1)
                    return;

                if (!item.sref_params ||
                    item.sref_params.type === params.type ||
                    (item.sref_params.type === 'user' && !params.type && itemSref === 'index.settings')) {
                    vm.currentItem = item;
                }
            });
            treeState.hideTreeOverlay();
        }

        function stateSuccess(trans) {
            var id = trans ? trans.$to().name : $state.current.name;
            stateChanged(trans); // Calling this here because $stateParams isn't correct until here
            doCheckItemActive(id, $state.href($state.current.name, $state.params, { absolute: false }));
        }

        function doCheckItemActive(id, parentUrl) {
            var activeItems = [];
            var allMenuItems = [...vm.menu.expanded, ...vm.menu.dropdown];

            for (var i = 0; i < allMenuItems.length; ++i) {
                var item = allMenuItems[i];
                item.isActive = false;
                var itemSref = item.parent_sref ? item.parent_sref : item.sref;
                if (itemSref === id || id.indexOf(itemSref) !== -1) {
                    if (item.sref_params && item.sref_params.type && parentUrl.indexOf(item.sref_params.type) === -1 &&
                        !(item.sref_params.type === 'user' && itemSref === 'index.settings')) {
                        item.isActive = false;
                    } else {
                        item.isActive = true;
                        activeItems.push(item);
                    }
                }
            }

            if (activeItems.length > 1) {
                var bestMatch = null;
                for (var j = 0; j < activeItems.length; j++) {
                    var item = activeItems[j];
                    if (!bestMatch) {
                        bestMatch = item;
                        continue;
                    }

                    if (bestMatch.sref && item.sref && id.indexOf(bestMatch.sref) === -1 && id.indexOf(item.sref) !== -1) {
                        bestMatch.isActive = false;
                        bestMatch = item;
                    }
                }
            }

            $scope.$applyAsync(recalulateDropdownMenuClassesInsideScope)
        }

        function recalulateDropdownMenuClassesInsideScope() {
            var newIsDropdownMenuHilighted = false;
            var newDropdownTitle = $translate.instant("MORE");
            vm.menu.dropdown.forEach((item) => {
                if (item.isActive) {
                    newIsDropdownMenuHilighted = true;
                    newDropdownTitle = item.translated;
                }
            });
            vm.isDropdownMenuHilighted = newIsDropdownMenuHilighted;
            vm.dropdownTitle = newDropdownTitle;
        }

        function recalculateLicense() {
            vm.edition = coreLicensing.edition;
            if (vm.features) {
                vm.rightItemChat.hidden = !vm.features.enableChat || vm.edition === 1 || claimsService.impersonating();
                vm.menuWorkspaces.hidden = !vm.features.enableWebConferencing || vm.edition === 1;
                vm.menuNewsFeeds.hidden = !vm.features.enableRssFeeds;
                vm.hasChat = !vm.rightItemChat.hidden;

                vm.addMenuOnlineMeeting.hidden = !vm.features.enableWebConferencing || vm.edition === 1;
            }
        }

        function toggleTree() {
            treeState.toggleTree(true);
        }

        function logout(event) {
            preferencesStorage.setSortingFilteringParam("chat", "doLogout", true);
            AuthenticationService.Logout("top menu bar user (logout)");
        }

        function openSectionMenu(e) {
            e.stopPropagation();
            //showAppNavMenu();
            return false;
        }

        function openHelp(pagesrc) {
            let page = pagesrc;
            if (!page && $rootScope.$state.current.name && $rootScope.$state.current.name.startsWith("index.reports."))
                page = $rootScope.$state.current.helpid;
            if (!page)
                page = $rootScope.$state.current.name;

            var version = stProductVersion;
            const url = "https://help.smartertools.com/smartermail/current/?page=" + encodeURIComponent(page) + "&v=" + version;
            console.log('NAVIGATING TO HELP PAGE: ' + url);
            window.open(url, "help");
        }

        function openCustomHelp() {
            window.open(vm.customHelpInfo.url, "customHelp");
        }

        function openAbout(ev) {
            $mdDialog.show({
                controller: "aboutSmartermailController",
                controllerAs: "ctrl",
                templateUrl: "app/root/modals/about.dlg.html",
                targetEvent: ev
            })
                .then(function (success) { }, function (cancel) { });
        }

        function openActiveSectionAddMenuItem() {
            switch (vm.currentItem.text) {
                case 'EMAIL': return openAddMenuItem('email');
                case 'CALENDAR': return openAddMenuItem('appointment');
                case 'CONTACTS': return openAddMenuItem('contact');
                case 'TASKS': return openAddMenuItem('task');
                case 'NOTES': return openAddMenuItem('note');
                case 'ONLINE_MEETINGS': return openAddMenuItem('meeting');
                default: return openAddMenuItem('email');
            }
        }

        function openAddMenuItem(item) {
            var url = window.location.href.substr(0, window.location.href.indexOf("#/")) + "#/popout/" + item + "/";
            var urlFeatures = 'resizable=1, ';
            switch (item) {
                case 'email': {
                    url += 'compose/';
                    urlFeatures += popupService.dimensions.email;
                    break;
                }
                case 'appointment': {
                    url += 'new';
                    urlFeatures += popupService.dimensions.calendar;
                    break;
                }
                case 'contact': {
                    url += 'new';
                    urlFeatures += popupService.dimensions.contact;
                    break;
                }
                case "meeting":
                    {
                        url += "new";
                        urlFeatures += popupService.dimensions.meeting;
                        break;
                    }
                case 'task': {
                    url += 'new';
                    urlFeatures += popupService.dimensions.task;
                    break;
                }
                case 'note': {
                    url += 'new';
                    urlFeatures += popupService.dimensions.note;
                    break;
                }
            }
            window.open(url, "", urlFeatures);
        }

        function openAdvancedSearch() {
            window.open((stSiteRoot || '/') + "interface/root#/popout/email-search", "", 'resizable=1, ' + popupService.dimensions.notification)
        }

        //function loadRecursiveFolders(folder, retarr) {
        //    for (var i = 0; i < folder.subFolders.length; ++i) {
        //        var f = folder.subFolders[i];
        //        f.path = f.path.toLowerCase();
        //        var index = 0;
        //        for (var j = 0; j < retarr.length; ++j) {
        //            if (retarr[j].path === folder.path) { index = j; break; }
        //        }
        //        if (!index) { index = retarr.length - 1; };
        //        f.name = folder.name + '\\' + f.name;
        //        retarr.splice(index + 1, 0, f);
        //        if (f.subFolders) {
        //            loadRecursiveFolders(f, retarr);
        //        }
        //    }
        //    return retarr;
        //};

        function refreshNotifications() {
            notifications.getNotificationCount()
                .then(function (data) {
                    $scope.userNotificationCount = data.userCount;
                    vm.userNotificationCount = data.userCount;
                    $scope.domainNotificationCount = data.domainCount;
                    vm.domainNotificationCount = data.domainCount;
                    $scope.systemNotificationCount = data.systemCount;
                    vm.systemNotificationCount = data.systemCount;
                    vm.userNotificationCountIndicator = vm.userNotificationCount + vm.domainNotificationCount;
                    vm.rightItemNotifications.counter = vm.userNotificationCountIndicator;
                });
        }

        function navTo(sref, params, reload) {
            if (params) {
                $state.go(sref, params);
            } else {
                $state.go(sref);
            }
            if (reload === true) {
                $timeout($state.reload);
            }
            treeState.hideTreeOverlay();
        }

        function refreshCustomHelp() {
            if (coreData.customHelpInfo && coreData.customHelpInfo.text !== "" && coreData.customHelpInfo.url !== "") {
                vm.customHelpInfo = coreData.customHelpInfo;
            } else {
                vm.customHelpInfo = null;
            }
        }
    }
})();
